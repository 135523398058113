import React from 'react';
import ReactApexChart from 'react-apexcharts';

const AssetsByCategory = ({ category_by_count }) => {
    const label = category_by_count.map((item) => item.category);
    const data = category_by_count.map((item) => item.count);
    const state = {
        series: data,
        options: {
            chart: {
                type: 'donut',
            },
            labels: label,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 100
                    },
                    legend: {
                        position: 'bottom'
                    }
                }
            }],
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '22px',
                                color: '#000',
                                offsetY: -10,
                                formatter: function () {
                                    return 'Assets By Category';
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '16px',
                                color: '#000',
                                offsetY: 16,
                                formatter: function (val) {
                                    return val;
                                }
                            },
                            total: {
                                show: true,
                                showAlways: true,
                                label: 'Total',
                                fontSize: '22px',
                                color: '#000',
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                                }
                            }
                        }
                    }
                }
            }
        }
    };

    return (
        <div>
            <div id="chart">
                <ReactApexChart options={state.options} series={state.series} type="donut" />
            </div>
            <div id="html-dist"></div>
        </div>
    );
};

export default AssetsByCategory;
