import React, { useEffect } from 'react'
import { Avatar, Button, Layout, Spin, Table, Badge, Popconfirm, Tag, Space, Typography, notification, Result, Drawer, Form, Input, Select, Col, Row, Divider, } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useState } from 'react';
import NavBar from '../components/Navbar';
import axios, { all } from 'axios';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { backend_url, childRow } from '../config/constants';
import { useForm } from 'antd/es/form/Form';
const { Header, Sider, Content } = Layout;
const { Option } = Select;

const CategoryManagement = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const [api, contextHolder] = notification.useNotification();
    const [form] = useForm();
    const userName = userData.username;
    const [isParent, setIsParent] = useState(null);
    const [locations, setLocations] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [data, setData] = useState([]);
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        form.resetFields();
        setSelectedCategory(null);
        setOpen(false);
    };

    const [ApiCallBackState, setApiCallBackState] = useState(
        <Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'UUID',
                    dataIndex: 'uid',
                    key: 'uid',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Category',
                    dataIndex: 'location',
                    key: 'location',
                },
                {
                    title: 'State',
                    dataIndex: 'state',
                    key: 'state',
                },
                {
                    title: 'Status',
                    key: 'status',
                    dataIndex: 'status'
                },
                {
                    title: 'Actions',
                    key: 'actions',
                }
            ]} />
        </Spin>
    )


    useEffect(() => {
        document.title = "Category Management";



        setApiCallBackState(<Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'UUID',
                    dataIndex: 'uid',
                    key: 'uid',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Category',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Description',
                    dataIndex: 'description',
                    key: 'description',
                },
                {
                    title: 'Actions',
                    key: 'actions',
                }
            ]} />
        </Spin>)
        const auth = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        axios.get(`${backend_url}/location.php`, auth).then((response) => {
            setLocations(response.data.map(item => ({
                label: <Typography.Text>{item.location}, <Typography.Text disabled>{item.state}</Typography.Text></Typography.Text>,
                value: item.uuid,
            })))
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
            setApiCallBackState(
                <Result
                    title={`An error occurred! ${error.response.data.message}`}
                    subTitle="Sorry, something went wrong. Please try again later!"
                />
            )
        })

        axios.get(`${backend_url}/categories.php`, auth).then((response) => {
            setData(response.data);
            setApiCallBackState(
                <Table
                    rowKey={(record) => record.id}
                    title={
                        () => <div style={childRow}>
                            <Typography.Title level={4}>Category Management</Typography.Title>
                            <Button type="primary" onClick={showDrawer}>
                                <PlusOutlined /> Add Category
                            </Button>
                        </div >
                    }
                    dataSource={response.data}
                    columns={[
                        {
                            title: 'UUID',
                            dataIndex: 'id',
                            key: 'id',
                            render: text => <a>{text}</a>,
                        },
                        {
                            title: 'Category',
                            dataIndex: 'name',
                            key: 'name',
                            filters: response.data.filter((category) => category.parent === 0).map((category) => {
                                return { text: category.name, value: category.id }
                            }),
                            onFilter: (value, record) => record.id === value,
                        },
                        {
                            title: 'Description',
                            dataIndex: 'description',
                            key: 'description',
                        },
                        {
                            title: 'Allowlisted Locations',
                            dataIndex: 'zones',
                            key: 'zones',
                            render: (zones, data) => {
                                if (data.parent === 0) {
                                    return <Typography.Text disabled>Expand to view</Typography.Text>
                                }
                                if (zones.length === 0) {
                                    return <Tag color="red">No allowlisted locations</Tag>
                                }
                                return <span>
                                    {zones.map((zone) => {
                                        return (
                                            <Tag color={zone.follow_parent ? 'green' : 'blue'}>
                                                {zone.location}, {zone.state}
                                            </Tag>
                                        )
                                    })}
                                </span>
                            }
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text) => (
                                <Tag color={text === 1 ? 'green' : 'red'}>{text === 1 ? 'Active' : 'Inactive'}</Tag>
                            ),
                            filters: [
                                { text: 'Active', value: 1 },
                                { text: 'Inactive', value: 0 },
                            ],
                            onFilter: (value, record) => record.status === value,
                        },
                        {
                            title: 'Actions',
                            key: 'actions',
                            render: (text, record) => (
                                <Space size="middle">
                                    <Button type="primary" icon={<EditOutlined />} onClick={() => {
                                        setSelectedCategory(record);
                                        setIsParent(record.parent);
                                        record.zones.map((zone) =>console.log(zone));
                                        form.setFieldsValue({
                                            name: record.name,
                                            description: record.description,
                                            status: record.status,
                                            parent: record.parent,
                                            allowlistedLocations: record.zones.map((zone) => zone.id)
                                        });
                                        showDrawer();
                                    }} />
                                    {(record.parent === 0) ?
                                        <Button type="primary" icon={<PlusOutlined />} onClick={() => {
                                            setSelectedCategory({
                                                id: record.id,
                                                name: record.name,
                                                enableSelection: true,
                                            });
                                            form.setFieldsValue({
                                                parent: record.id
                                            });
                                            showDrawer();
                                        }} />
                                        : null
                                    }
                                    <Popconfirm
                                        title="Are you sure you want to delete this location?"
                                        onConfirm={() => {
                                            axios.delete(`${backend_url}/categories.php`, { data: { id: record.id }, ...auth }).then((response) => {
                                                setOpen('')
                                                api.success({
                                                    message: 'Category deleted!',
                                                    description: 'Category has been deleted successfully!',
                                                });
                                            }).catch((error) => {
                                                api.error({
                                                    message: 'An error occurred!',
                                                    description: 'Please try again later! ' + error,
                                                });
                                                console.log(error)
                                            })
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="danger" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Space>
                            ),
                        }
                    ]} />
            )
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
            setApiCallBackState(
                <Result
                    title={`An error occurred! ${error.response.data.message}`}
                    subTitle="Sorry, something went wrong. Please try again later!"
                />
            )
        })
    }, [open])

    const getCategories = () => {
        let _temp = data.map((category) => {
            return { label: category.name, value: category.id }
        })
        if (selectedCategory != null && !selectedCategory.enableSelection) {
            _temp = _temp.filter((category) => category.value !== selectedCategory.id)
        }
        _temp.push({ label: 'None', value: 0 })
        return _temp
    }

    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            {contextHolder}
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        Category Management
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', padding: '0px 16px 0 16px', overflowY: 'auto', backgroundColor: "white", borderRadius: ".75rem" }}>
                    <Drawer
                        title="Create and modify category"
                        width={720}
                        onClose={onClose}
                        open={open}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            },
                        }}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button onClick={() => {
                                    form.submit();
                                }} type="primary">
                                    Submit
                                </Button>
                            </Space>
                        }
                    >
                        <Form
                            layout="vertical"
                            name="categories_form"
                            form={form}
                            onFinish={(values) => {
                                if (isParent === 0) {
                                    values.allowlistedLocations = [];
                                }
                                if (selectedCategory == null || selectedCategory.enableSelection) {
                                    axios.post(`${backend_url}/categories.php`, values, auth).then((response) => {
                                        api.success({
                                            message: 'Category added successfully!',
                                            description: 'Category has been added successfully!',
                                        });
                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                } else {
                                    values.id = selectedCategory.id;
                                    axios.put(`${backend_url}/categories.php`, values, auth).then((response) => {
                                        api.success({
                                            message: 'Category updated!',
                                            description: 'Category has been updated successfully!',
                                        });

                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                }
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="name"
                                        label="Category Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a category',
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter category" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="parent"
                                        label="Parent Category"
                                    >
                                        <Select placeholder="Select a parent category" allowClear options={getCategories()} onChange={(value) => { 
                                            form.setFieldsValue({ parent: value }); 
                                            setIsParent(value);
                                        }} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="description"
                                        label="Description"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter category description',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Please enter category description" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select a status',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select a status">
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    {
                                        isParent === 0 ? <Typography.Text disabled italic>Parent Category can't be assigned to locations</Typography.Text> : <Form.Item
                                            name="allowlistedLocations"
                                            label="Allowlisted Locations"
                                        >
                                            <Select
                                                mode="multiple"
                                                allowClear
                                                options={locations}
                                                onChange={(value) =>console.log(value)}
                                                placeholder="Select allowlisted locations"
                                            />
                                        </Form.Item>
                                    }
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {ApiCallBackState}
                </Content>
            </Layout>
        </Layout>
    );
}

export default CategoryManagement
