import React, { useEffect } from 'react'
import { Avatar, Button, Layout, Spin, Modal, Table, Popconfirm, Cascader, Tag, Space, Typography, notification, Result, Drawer, Form, Input, Select, DatePicker, Col, Row, message, Card, } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useState } from 'react';
import NavBar from '../components/Navbar';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { backend_url, childRow } from '../config/constants';
import { useForm } from 'antd/es/form/Form';
import { Link } from 'react-router-dom';
import { GroupTransfer } from '../components/functions/GroupTransfer';
const { Header, Sider, Content } = Layout;
const { Option } = Select;

const UserManagement = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const [api, contextHolder] = notification.useNotification();
    const [form] = useForm();
    const userName = userData.username;
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [options, setOptions] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [drawer, setDrawer] = useState(<>
        <Spin tip="Loading..." />
    </>);
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setRefresh(false);
        form.resetFields();
        setSelectedEmail(null);
        setOpen(false);
    };
    const [ApiCallBackState, setApiCallBackState] = useState(
        <Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: 'Access Level',
                    dataIndex: 'access',
                    key: 'access',
                },
                {
                    title: 'Location',
                    key: 'location',
                }, {
                    title: 'Phone Number',
                    key: 'phone',
                }
            ]} />
        </Spin>
    )


    useEffect(() => {
        document.title = "User Management";
        setApiCallBackState(<Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'Name',
                    dataIndex: 'name',
                    key: 'name',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: 'Access Level',
                    dataIndex: 'access',
                    key: 'access',
                },
                {
                    title: 'Location',
                    key: 'location',
                }, {
                    title: 'Phone Number',
                    key: 'phone',
                }
            ]} />
        </Spin>)
        const auth = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }

        axios.get(`${backend_url}/location.php?status=active`, auth).then((response) => {
            let option = []
            response.data.forEach(location => {
                const found = option.find(option => option.value === location.state)
                if (!found) {
                    option.push({
                        value: location.state,
                        label: location.state,
                        children: [
                            {
                                value: location.uuid,
                                label: location.location,
                            }
                        ]
                    })
                } else {
                    option.forEach(option => {
                        if (option.value === location.state) {
                            option.children.push({
                                value: location.uuid,
                                label: location.location,
                            })
                        }
                    })
                }
                setOptions(option)
            })
            if (selectedEmail) {
                const accessLevel = (level) => {
                    return ['User', 'Manager', 'Administrator'][level]
                }

                axios.get(`${backend_url}/users.php?email=${selectedEmail}`, auth).then((response) => {
                    if (response.data.user.access_level === -1) {
                        Modal.info({
                            title: 'Allow User to login?',
                            content: 'To unblock the user, please change the access level to User, Manager or Administrator.'
                        })
                    }

                    form.setFieldsValue({
                        name: response.data.user.username,
                        email: response.data.user.email,
                        access_level: accessLevel(response.data.user.access_level),
                        location: [response.data.user.state, response.data.user.location],
                        phone_number: response.data.user.phone_number
                    })
                }).catch((error) => {
                    api.error({
                        message: 'An error occurred!',
                        description: 'Please try again later! ' + error,
                    });
                    console.log(error)
                })
            }
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
        })

        axios.get(`${backend_url}/users.php`, auth).then((response) => {
            setApiCallBackState(
                <Table
                    title={
                        () => <div style={childRow}>
                            <Typography.Title level={4}>User Management</Typography.Title>
                            <Button type="primary" onClick={showDrawer}>
                                <PlusOutlined /> Add User
                            </Button>
                        </div >
                    }
                    dataSource={response.data.users} columns={
                        [
                            {
                                title: 'Name',
                                dataIndex: 'username',
                                key: 'username',
                                render: text => <a>{text}</a>,
                            },
                            {
                                title: 'Email',
                                dataIndex: 'email',
                                key: 'email',
                            },
                            {
                                title: 'Access Level',
                                dataIndex: 'access_level',
                                key: 'access_level',
                                filters: [
                                    { text: 'User', value: 0 },
                                    { text: 'Manager', value: 1 },
                                    { text: 'Administrator', value: 2 },
                                    { text: 'Blocked', value: -1 }
                                ],
                                onFilter: (value, record) => record.access_level === value,
                                render: (access_level, record) => {
                                    let color = 'red';
                                    let tag = 'Blocked';
                                    if (access_level === 1) {
                                        color = 'orange';
                                        tag = 'Manager';
                                    } else if (access_level === 2) {
                                        color = 'green';
                                        tag = 'Administrator';
                                    } else if (access_level === 0) {
                                        color = 'blue';
                                        tag = 'User';
                                    }
                                    return <>
                                        <Tag color={color} key={access_level}>{tag}</Tag>
                                        {
                                            access_level === -1 && <a
                                                onClick={() => {
                                                    Modal.info({
                                                        title: `Unable to delete ${record.username}`,
                                                        content: <>
                                                            {record.username} may have assets assigned to him/her. An asset cannot be deleted untill it completed its lifecycle (from purchased to disposed). You can reassign these items to another user and then delete {record.username}.
                                                            <br />
                                                            <br />
                                                            <Typography.Text strong>At the moment {record.username} is blocked and cannot access the system.</Typography.Text>
                                                            <br />
                                                            <br />
                                                            <a onClick={
                                                                () => {
                                                                    Modal.destroyAll();
                                                                    window.location.href = `#/assets?asset_user_email=${record.email}&instanceof=UserManagementInterface`
                                                                }
                                                            }>View Assigned Assets</a>
                                                        </>,
                                                    });
                                                }}
                                            >Learn why?
                                            </a>
                                        }
                                    </>;
                                }
                            },
                            {
                                title: 'Location',
                                key: 'location',
                                dataIndex: 'location'
                            }, {
                                title: 'Phone Number',
                                key: 'phone',
                                dataIndex: 'phone_number',
                            }, {
                                title: 'Action',
                                key: 'action',
                                render: (text, record) => {
                                    return <>
                                        <Button type="primary" onClick={() => {
                                            setSelectedEmail(record.email); setRefresh(true); showDrawer(true);
                                        }}>
                                            <EditOutlined />
                                        </Button>
                                        &nbsp;
                                        <Popconfirm
                                            title="Delete the user"
                                            description="Are you sure you want to delete the user?"
                                            onConfirm={() => {
                                                message.info('Starting system validations...');
                                                axios.delete(`${backend_url}/users.php?email=${record.email}`, auth).then((response) => {
                                                    api.success({
                                                        message: 'User deleted!',
                                                        description: 'User has been deleted successfully!',
                                                    });
                                                    setRefresh(true);
                                                }).catch((error) => {
                                                    if (error.response.data.message === 'User is a Project Head.') {
                                                        onUserHasAssets(record)
                                                        return
                                                    }
                                                    api.error({
                                                        message: 'An error occurred!',
                                                        description: 'Please try again later! ' + error.response.data.message,
                                                    });
                                                    console.log(error)
                                                })
                                            }}
                                            onCancel={() => {

                                            }}
                                            okText="Yes"
                                            cancelText="No"
                                        >
                                            <Button danger>
                                                <DeleteOutlined />
                                            </Button>
                                        </Popconfirm>
                                    </>
                                },
                            }
                        ]} />
            )
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
            setApiCallBackState(
                <Result
                    title={`An error occurred! ${error.response.data.message}`}
                    subTitle="Sorry, something went wrong. Please try again later!"
                />
            )
        })
    }, [refresh])

    const onUserHasAssets = (record) => {
        setApiCallBackState(
            <Result
                status="warning"
                title={`${record.username} has assets assigned to them.`}
                subTitle="User has assets assigned to them that aren't disposed. Please re-assign the assets to another user before deactivating the user."
                extra={
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Link to={`/assets?asset_user_email=${record.email}&instanceof=UserManagementInterface`}>
                            <Button type="primary" key="console">
                                View Linked Assets
                            </Button>
                        </Link>
                        &nbsp;
                        <GroupTransfer refresh={refresh} setRefresh={setRefresh} email={record.email} location={record.location} />
                        &nbsp;

                        <Button danger onClick={() => {
                            axios.delete(`${backend_url}/users.php?email=${record.email}&force=true`, auth).then((response) => {
                                api.success({
                                    message: 'User deleted!',
                                    description: 'User has been deleted successfully!',
                                });
                                setRefresh(true);
                            }).catch((error) => {
                                if (error.response.data.message === 'User is a Project Head.') {
                                    onUserHasAssets(record)
                                    return
                                }
                                api.error({
                                    message: 'An error occurred!',
                                    description: 'Please try again later! ' + error.response.data.message,
                                });
                                console.log(error)
                            })
                        }}>
                            Force Delete
                        </Button>
                        &nbsp;

                        <Button onClick={() => {
                            setRefresh(true);
                        }}>
                            Cancel
                        </Button>
                    </div>
                }
            />
        )
    }

    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            {contextHolder}
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        User Management
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', padding: '0px 16px 0 16px', overflowY: 'auto', backgroundColor: "white", borderRadius: ".75rem" }}>
                    <Drawer
                        title="Create a new account"
                        width={720}
                        onClose={onClose}
                        open={open}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            },
                        }}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button onClick={() => {
                                    setRefresh(true);
                                    form.submit();
                                }} type="primary">
                                    Submit
                                </Button>
                            </Space>
                        }
                    >
                        <Form
                            layout="vertical"
                            name="user_form"
                            form={form}
                            onFinish={(values) => {
                                values.location = values.location[1]
                                values.access_level = parseInt(values.access_level)
                                if (selectedEmail == null) {
                                    axios.post(`${backend_url}/users.php`, values, auth).then((response) => {
                                        const user_email = response.data.data.email;
                                        const user_password = response.data.data.password;
                                        Modal.info({
                                            title: 'User has been Created',
                                            content: (
                                                <div>
                                                    {/* user info */}
                                                    <Card title="Account Credential Manager" bordered={false}>
                                                        <p>Email: {user_email}</p>
                                                        <p>Password: {user_password}</p>
                                                    </Card>
                                                </div>
                                            ),
                                            onOk() { },
                                        });
                                        api.success({
                                            message: 'User added successfully!',
                                            description: 'User has been added successfully!',
                                        });
                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                } else {
                                    axios.put(`${backend_url}/users.php`, values, auth).then((response) => {
                                        api.success({
                                            message: 'User updated!',
                                            description: 'User has been updated successfully!',
                                        });

                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                }
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label="Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter user name',
                                            },
                                            {
                                                min: 3,
                                                message: 'Name must be at least 3 characters long',
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter user name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter user email',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Please enter a valid email',
                                            }
                                        ]}
                                    >
                                        <Input disabled={(selectedEmail == null) ? false : true} placeholder="Please enter user email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="phone_number"
                                        label="Phone Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter user Phone Number',
                                            },
                                            {
                                                min: 9,
                                                message: 'Phone Number must be at least 9 characters long',
                                            },
                                            {
                                                max: 10,
                                                message: 'Phone Number must be at most 10 characters long',
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={6}>
                                    <Form.Item
                                        name="access_level"
                                        label="Access Level"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select an access_level',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Please select an owner">
                                            <Option value="2">Administrator</Option>
                                            <Option value="1">Manager</Option>
                                            <Option value="0">User</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={18}>
                                    <Form.Item
                                        name="location"
                                        label="Location"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please choose the location',
                                            },
                                        ]}
                                    >
                                        <Cascader options={options} placeholder="Please select user" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {ApiCallBackState}
                </Content>
            </Layout>
        </Layout>
    );
}

export default UserManagement
