import React, { useEffect, useState } from 'react'
import AssetFilter from './functions/AsserFilter';
import { Button, Col, Row, Collapse, Statistic, Modal, notification, Spin, Table, Divider, message, Tag, Switch, Typography, Drawer, Descriptions, Tooltip, Popconfirm } from 'antd';
import AddOrModifyAsset from './AddOrModifyAsset';
import axios from 'axios';
import { WarningOutlined, CopyOutlined, CheckCircleOutlined, ArrowDownOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { backend_url } from '../config/constants';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const AssetAdmin = () => {
    const [analytics, setAnalytics] = useState([])
    const [api, contextHolder] = notification.useNotification();
    const [refresh, setRefresh] = useState(false)
    const [fullMode, setFullMode] = useState(false)
    const [open, setOpen] = useState(false)
    const [pageConfig, setPageConfig] = useState({ page: 1, pageSize: 10 })
    const [openExtraText, setOpenExtraText] = useState(false)
    const [assetFilter, setAssetFilter] = useState(null)
    const [data, setData] = useState([])
    const [columns, setColumns] = useState([])
    const [id, setId] = useState(null)
    const access_id = JSON.parse(localStorage.getItem('userdata')).access_level;

    const query = new URLSearchParams(useLocation().search);
    const assetUserEmail = query.get('asset_user_email');

    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    const [ApiCallBackState, setApiCallBackState] = useState(
        <Spin tip="Loading...">
            <Table dataSource={[]} />
        </Spin>
    )

    const exportData = (noFilter = false) => {
        axios.post(`${backend_url}/exports.php?action=products`, noFilter ? {} : assetFilter, auth).then((response) => {
            if (response.data.file === undefined) {
                notification.error({
                    message: 'Export Failed',
                    description: 'Could not export data to CSV file. Please try again later.',
                })
                return
            }
            const file_location = `${backend_url}/${response.data.file}`;
            const new_window = window.open(file_location, '_blank');
            if (new_window) {
                new_window.location = file_location;
            } else {
                notification.error({
                    message: 'Export Failed',
                    description: 'Could not export data to CSV file. Pop is blocked.',
                    btn: <Button type="primary" onClick={() => {
                        window.location = file_location;
                    }}>Download anyways</Button>
                })
                return
            }
            notification.success({
                message: 'Export Complete',
                description: 'Data has been exported to CSV file. Please check your downloads folder.',
            })
        }).catch((error) => {
            console.log(error)
            if (error.response) {
                notification.error({
                    message: 'Export Failed',
                    description: error.response.data.message,
                })
                return
            }
            notification.error({
                message: 'Export Failed',
                description: 'Could not export data to CSV file. Please try again later.',
            })
        });
    }

    const extractWarrantyData = (data) => {
        let arr = []
        for (let i = 0; i < data.length; i++) {
            arr.push(
                {
                    title: data[i].name,
                    code: data[i].code,
                    expiry: new Date(data[i].expiry).toLocaleDateString()
                }
            )
        }
        return <Table
            columns={[
                {
                    title: 'Name',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    render: (text) => <Link to={`/asset?id=${text}`}>{text}</Link>
                },
                {
                    title: 'Expiry',
                    dataIndex: 'expiry',
                    key: 'expiry',
                }
            ]}
            dataSource={arr}
        />
    }

    const fetchAssets = (page, pageSize, filter = assetFilter) => {
        const url = filter
            ? `${backend_url}/products.php?page=${page}&limit=${pageSize}&method=filtered`
            : `${backend_url}/products.php?page=${page}&limit=${pageSize}`;

        const fetchData = filter
            ? axios.post(url, filter, auth)
            : axios.get(url, auth);

        fetchData.then(response => {
            setData(response.data);
        }).catch(error => {
            api.error({
                message: 'Could not fetch data',
                description: 'Please try again later'
            });
        });

        setColumns(generateColumns(fullMode));
    };

    const generateColumns = (fullMode) => {
        if (fullMode) {
            return [{
                title: 'Project Head',
                dataIndex: 'asset_parent_category',
                key: 'asset_parent_category',
                render: (text, record) => (
                    <div>
                        {record.asset_parent_category_name}
                    </div>
                )
            },
            {
                title: 'Status',
                dataIndex: 'asset_status',
                key: 'asset_status',
                fixed: 'left',
                render: (text, record) => {
                    const values = ['Pending Approval', 'Approved', 'Rejected', 'Deleted'];
                    const colors = ['orange', 'green', 'red', 'blue'];
                    return (
                        <div>
                            <Tag color={colors[text]}>{values[text]}</Tag>
                            &nbsp;
                            {
                                (record.asset_disposal_date != null) && <Tag color="blue">Disposed</Tag>
                            }
                        </div>
                    )
                }
            },
            {
                title: 'Accounts Ledger',
                dataIndex: 'asset_category_name',
                key: 'asset_category_name',
                render: (text) => (
                    <div>
                        {text}
                    </div>
                )
            },
            {
                title: 'Acquisition Date',
                dataIndex: 'asset_bought_on',
                key: 'asset_bought_on',
                fixed: 'left',
                sorter: (a, b) => new Date(a.asset_bought_on) - new Date(b.asset_bought_on),
                render: (text) => (
                    <div>
                        {new Date(text).toLocaleDateString()}
                    </div>
                )
            },
            {
                title: 'Year',
                dataIndex: 'asset_bought_on',
                key: 'asset_bought_on',
                sorter: (a, b) => new Date(a.asset_bought_on) - new Date(b.asset_bought_on),
                render: (text) => {
                    if (new Date(text).getMonth() > 4) {
                        return (
                            <div>
                                {new Date(text).getFullYear()}-{new Date(text).getFullYear() + 1}
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {(new Date(text).getFullYear() - 1)}-{new Date(text).getFullYear()}
                            </div>
                        );
                    }
                }
            },
            {
                title: 'Bill Number',
                dataIndex: 'asset_bill_number',
                key: 'asset_bill_number',
            },
            {
                title: 'Acquisition Cost',
                dataIndex: 'asset_price',
                key: 'asset_price',
                fixed: 'left',
                render: (text, record) => (
                    <a style={{ cursor: "pointer" }} onClick={() => {
                        const total_dep = record.asset_depreciation * (new Date().getFullYear() - new Date(record.asset_bought_on).getFullYear());
                        const price = (record.asset_depreciated_value > 0 )? record.asset_depreciated_value.toFixed(2) : 0;
                        setOpenExtraText(
                            <Descriptions bordered>
                                <Descriptions.Item label="Cost Price">₹{text}</Descriptions.Item>
                                <Descriptions.Item label="Depreciated Value">₹{price} <Tag color="red">{total_dep}%</Tag></Descriptions.Item>
                                <Descriptions.Item label="Depreciation Rate"><Tag color="red"><ArrowDownOutlined />&nbsp;&nbsp;{record.asset_depreciation}%</Tag></Descriptions.Item>
                            </Descriptions>
                        )
                    }}>
                        ₹{text}
                    </a>
                )
            },
            {
                title: 'Vendor',
                dataIndex: 'asset_vendor_disposal_name',
                key: 'asset_vendor_disposal_name',
                render: (text, record) => (
                    <div>
                        {(text == null) ? <Tag color="green">Active</Tag> : text}
                    </div>
                )
            },
            {
                title: 'Voucher Code',
                dataIndex: 'asset_voucher_code',
                key: 'asset_voucher_code',
            },
            {
                title: 'Product Code',
                dataIndex: 'asset_code',
                key: 'asset_code',
                fixed: 'left',
                render: text => <>
                    <Link to={`/asset?id=${text}`}>{text}</Link>
                </>
            },
            {
                title: 'Description',
                dataIndex: 'asset_description',
                key: 'asset_description',
                render: (text) => {
                    // show only first 40 characters
                    if (text.length < 40) {
                        return (
                            <div>
                                {text}
                            </div>
                        )
                    }
                    return (
                        <a onClick={() => setOpenExtraText(text)} style={{ cursor: "pointer" }}>
                            {text.substring(0, 40)}...
                        </a>
                    )
                }
            },
            {
                title: 'Location',
                dataIndex: 'asset_location_name',
                key: 'asset_location_name',
                render: (text, record) => (
                    <div>
                        {text}, {record.asset_location_state}
                    </div>
                )
            },
            {
                title: 'Warranty Expiry',
                dataIndex: 'asset_warranty_expiry',
                key: 'asset_warranty_expiry',
                render: (text, record) => {
                    const date = new Date(text);
                    const current_date = new Date();
                    const diff = Math.abs(date - current_date);
                    const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
                    if (current_date > date) {
                        return (
                            <div>
                                <Tag style={{ margin: "5px 0px" }} color="red">{date.toLocaleDateString()}&nbsp;&nbsp;<WarningOutlined /></Tag>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                {date.toLocaleDateString()}<br />
                                <Tag style={{ margin: "5px 0px" }} color="green">{days} days left</Tag>
                            </div>
                        )
                    }
                }
            },
            {
                title: 'Remarks',
                dataIndex: 'asset_remark',
                key: 'asset_remark',
                render: (text) => {
                    if (text == null || text === "") {
                        return (
                            <Typography.Text type="secondary">No remarks</Typography.Text>
                        )
                    }
                    if (text.length < 40) {
                        return (
                            <div>
                                {text}
                            </div>
                        )
                    }
                    return (
                        <a onClick={() => setOpenExtraText(text)} style={{ cursor: "pointer" }}>
                            {text.substring(0, 40)}...
                        </a>
                    )
                }
            },
            {
                title: 'Closing Balance',
                dataIndex: 'asset_depreciated_value',
                key: 'asset_depreciated_value',
                render: (text, record) => {
                    const bought_year = new Date(record.asset_bought_on).getFullYear();
                    const current_year = new Date().getFullYear();;
                    const price = record.asset_depreciation * (current_year - bought_year);
                    return <div>
                        ₹{text}&nbsp;&nbsp;
                        <Tag style={{ margin: "5px 0px" }} color="red"><ArrowDownOutlined />{price < 0 ? 0 : price}%</Tag>
                    </div>
                }
            },
            {
                title: 'Action',
                key: 'asset_depreciated_value',
                fixed: 'right',
                render: (text, record) => (
                    <div style={{ display: 'flex' }}>
                        {
                            (record.asset_status !== 3) &&
                            <>
                                <Button type="primary" onClick={() => { setId(record); setOpen(true); }}><EditOutlined /></Button>&nbsp;

                                <Popconfirm
                                    title="Are you sure you want to delete this asset?"
                                    onConfirm={() => {
                                        if (record.asset_disposal_date == null) {
                                            message.error('Asset is not disposed')
                                            return;
                                        }
                                        const form = new FormData();
                                        form.append('asset_code', record.asset_code);
                                        form.append('asset_status', 3);
                                        form.append('approval', 0);
                                        axios.post(`${backend_url}/products.php`, form, {
                                            headers: {
                                                'Content-Type': 'multipart/form-data',
                                                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                                            }
                                        }).then((response) => {
                                            message.success('Asset Deleted')
                                            setRefresh(!refresh)
                                        }).catch((error) => {
                                            message.error('Could not delete asset')
                                        })
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger><DeleteOutlined /></Button>
                                </Popconfirm>
                            </>
                        }
                        &nbsp;
                        {
                            (record.asset_status === 0) &&
                            <>
                                <Button onClick={() => intiateApprovalProceedings(record)}><CheckCircleOutlined /></Button>
                            </>
                        }
                        {
                            (record.asset_status === 2) &&
                            <>
                                <Button danger onClick={() => {
                                    intiateApprovalProceedings(record, true)
                                }}><CheckCircleOutlined /></Button>
                            </>
                        }
                    </div>
                ),
            }];
        } else {
            return [
                {
                    title: 'Date',
                    dataIndex: 'asset_bought_on',
                    key: 'asset_bought_on',
                    sorter: (a, b) => new Date(a.asset_bought_on) - new Date(b.asset_bought_on),
                    render: (text) => {
                        return (
                            <div>
                                {new Date(text).toLocaleDateString()}
                            </div>
                        )
                    }
                },
                {
                    title: "FY",
                    dataIndex: 'asset_bought_on',
                    sorter: (a, b) => new Date(a.asset_bought_on) - new Date(b.asset_bought_on),
                    render: (text) => {
                        if (new Date(text).getMonth() > 4) {
                            return (
                                <div>
                                    {new Date().getFullYear(text)}-{new Date(text).getFullYear() + 1}
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {(new Date().getFullYear(text) - 1)}-{new Date(text).getFullYear()}
                                </div>
                            );
                        }
                    }
                },
                {
                    title: 'Code',
                    dataIndex: 'asset_code',
                    key: 'asset_code',
                    render: text => <>
                        <Link to={`/asset?id=${text}`}>{text}</Link>
                    </>
                },
                {
                    title: 'Name',
                    dataIndex: 'asset_name',
                    key: 'asset_name',
                },
                {
                    title: 'Category',
                    dataIndex: 'asset_category',
                    key: 'asset_category',
                    render: (text, record) => (
                        <div>
                            <Tag color="orange">{record.asset_parent_category_name}</Tag>
                            <Tag color="magenta">{record.asset_category_name}</Tag>
                        </div>
                    )
                },
                {
                    title: 'Asset User',
                    dataIndex: 'asset_user_email',
                    key: 'asset_user_email',
                    render: (text, record) => (
                        <div>
                            <Tooltip title={record.asset_user_email}>
                                <a href={`mailto:${record.asset_user_name}`}>{record.asset_user_email}</a>
                            </Tooltip>
                        </div>
                    )
                },
                {
                    title: 'Price',
                    dataIndex: 'asset_price',
                    key: 'asset_price',
                    render: (text) => (
                        <div>
                            ₹{text}
                        </div>
                    )
                },
                {
                    title: 'Current Price',
                    dataIndex: 'asset_depreciated_value',
                    key: 'asset_depreciated_value',
                    render: (text, record) => {
                        const bought_year = new Date(record.asset_bought_on).getFullYear();
                        const current_year = new Date().getFullYear();
                        const percent = (record.asset_depreciation * (current_year - bought_year)).toFixed(2);
                        const price = text.toFixed(2) > 0 ? text.toFixed(2) : 0;
                        return <div>
                            ₹{price}&nbsp;&nbsp;
                            <Tag style={{ margin: "5px 0px" }} color="red"><ArrowDownOutlined />{percent}%</Tag>
                        </div>
                    }
                },
                {
                    title: 'Warranty Expiry',
                    dataIndex: 'asset_warranty_expiry',
                    key: 'asset_warranty_expiry',
                    render: (text, record) => {
                        const current_date = new Date();
                        const warranty_expiry = new Date(record.asset_warranty_expiry);
                        const diff = Math.abs(warranty_expiry - current_date);
                        const days = Math.ceil(diff / (1000 * 60 * 60 * 24));
                        if (current_date > warranty_expiry) {
                            return (
                                <div>
                                    <Tag style={{ margin: "5px 0px" }} color="red">{warranty_expiry.toLocaleDateString()}&nbsp;&nbsp;<WarningOutlined /></Tag>
                                </div>
                            )
                        } else {
                            return (
                                <div>
                                    {warranty_expiry.toLocaleDateString()}<br />
                                    <Tag style={{ margin: "5px 0px" }} color="green">{days} days left</Tag>
                                </div>
                            )
                        }
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'asset_status',
                    key: 'asset_status',
                    sorter: (a, b) => a.asset_status - b.asset_status,
                    filters: [
                        { text: 'Pending Approval', value: 0 },
                        { text: 'Approved', value: 1 },
                        { text: 'Deleted', value: 2 },
                        { text: 'Disposed', value: 3 }
                    ],
                    onFilter: (value, record) => record.asset_status === value,
                    render: (text, record) => {
                        const values = ['Pending Approval', 'Approved', 'Rejected', 'Deleted'];
                        const colors = ['orange', 'green', 'red', 'blue'];
                        return (
                            <div>
                                <Tag color={colors[text]}>{values[text]}</Tag>
                                &nbsp;
                                {
                                    (record.asset_disposal_date != null) && <Tag color="blue">Disposed</Tag>
                                }
                            </div>
                        )
                    }
                },
                {
                    title: 'Action',
                    key: 'action',
                    render: (text, record) => (
                        <div style={{ display: 'flex' }}>
                            {
                                (record.asset_status !== 3 && (access_id > 0 || record.asset_status === 0)) &&
                                <>
                                    <Button type="primary" onClick={() => { setId(record); setOpen(true); }}><EditOutlined /></Button>&nbsp;

                                    <Popconfirm
                                        title="Are you sure you want to delete this asset?"
                                        onConfirm={() => {
                                            if (record.asset_disposal_date == null) {
                                                message.error('Asset is not disposed')
                                                setOpenExtraText(
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                        <Typography.Title level={4} style={{ marginLeft: "10px", marginTop: "0px" }}><WarningOutlined />&nbsp;This asset is protected by system untill disposed properly!</Typography.Title>
                                                        <Typography.Text style={{ marginLeft: "10px" }}>Please dispose this asset properly before deleting it. Check with your administrator to delete this record.</Typography.Text>
                                                        {
                                                            localStorage.getItem('userdata') && JSON.parse(localStorage.getItem('userdata')).access_level > 0 && <>
                                                                <Divider plain>Admin Mode <Tag color="red">Active</Tag></Divider>
                                                                <Typography.Text style={{ marginLeft: "10px" }}>You are in admin mode. You can dispose this asset without proper disposal.</Typography.Text>

                                                                <Button type="primary" style={{ width: "100px", marginTop: "10px" }} onClick={() => {
                                                                    delete_asset(record)
                                                                }}>Dispose</Button>
                                                            </>
                                                        }
                                                    </div>
                                                )
                                                return;
                                            }
                                            delete_asset(record)
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button danger><DeleteOutlined /></Button>
                                    </Popconfirm>
                                </>
                            }
                            &nbsp;
                            {
                                (record.asset_status === 0 && access_id > 0) &&
                                <>
                                    <Button onClick={() => intiateApprovalProceedings(record)}><CheckCircleOutlined /></Button>
                                </>
                            }
                            {
                                (record.asset_status === 2 && access_id > 0) &&
                                <>
                                    <Button danger onClick={() => {
                                        intiateApprovalProceedings(record, true)
                                    }}><CheckCircleOutlined /></Button>
                                </>
                            }
                        </div>
                    ),
                },
            ];
        }
    };

    const delete_asset = (record) => {
        const form = new FormData();
        form.append('asset_code', record.asset_code);
        form.append('asset_status', 3);
        form.append('approval', 0);
        axios.post(`${backend_url}/products.php`, form, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }).then((response) => {
            message.success('Asset Deleted')
            setRefresh(!refresh)
        }).catch((error) => {
            message.error('Could not delete asset')
        })
    }

    useEffect(() => {
        setApiCallBackState(
            <Table
                dataSource={[]}
                loading={true}
            />
        )
        axios.get(`${backend_url}/stats.php?stat=asset`, auth).then((response) => {
            setAnalytics(response.data)
        }).catch((error) => {
            message.error('Could not fetch data')
        })
        if (assetUserEmail) {
            setAssetFilter({ asset_user_email: assetUserEmail, asset_disposal_mode: "available" })
            fetchAssets(pageConfig.page, pageConfig.pageSize, { asset_user_email: assetUserEmail, asset_disposal_mode: "available" })
        } else {
            fetchAssets(pageConfig.page, pageConfig.pageSize)
        }
    }, [refresh, fullMode])

    const intiateApprovalProceedings = (record, alterMode = false) => {
        setOpenExtraText(
            <>
                <Typography.Title level={4} style={{ marginLeft: "10px" }}><WarningOutlined />&nbsp;{!alterMode ? 'Approve Asset Alert!' : 'Change Asset Status'}</Typography.Title>
                <Typography.Text style={{ marginLeft: "10px" }}>Are you sure you want to approve this asset?</Typography.Text>
                <br />
                <br />
                <Descriptions bordered>
                    <Descriptions.Item label="Product Code">{record.asset_code}</Descriptions.Item>
                    <Descriptions.Item label="Product Name">{record.asset_name}</Descriptions.Item>
                    <Descriptions.Item label="Product Category">{record.asset_category_name}</Descriptions.Item>
                    <Descriptions.Item label="Initiator">{record.asset_user_name}</Descriptions.Item>
                    <Descriptions.Item label="Acquisition Date">{new Date(record.asset_bought_on).toLocaleDateString()}</Descriptions.Item>
                    <Descriptions.Item label="Acquisition Cost">₹{record.asset_price}</Descriptions.Item>
                    <Descriptions.Item label="Vendor">{record.asset_vendor_name}</Descriptions.Item>
                    <Descriptions.Item label="Location">{record.asset_location_name}, {record.asset_location_state}</Descriptions.Item>
                </Descriptions>
                <br />
                <br />
                <Button type="primary" onClick={() => {
                    const form = new FormData();
                    form.append('asset_code', record.asset_code);
                    form.append('asset_status', 1);
                    form.append('approval', 1);
                    console.log(form)
                    axios.post(`${backend_url}/products.php`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        message.success('Asset Approved')
                        setOpenExtraText(false)
                        setRefresh(!refresh)
                    }).catch((error) => {
                        message.error('Could not approve asset')
                    })
                }}>Approve</Button>
                &nbsp;
                <Button danger onClick={() => {
                    const form = new FormData();
                    form.append('asset_code', record.asset_code);
                    form.append('asset_status', 2);
                    form.append('approval', 0);
                    axios.post(`${backend_url}/products.php`, form, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        message.success('Asset Rejected')
                        setOpenExtraText(false)
                        setRefresh(!refresh)
                    }).catch((error) => {
                        message.error('Could not reject asset')
                    })
                }}>Reject</Button>
            </>
        )
    }

    return (
        <div style={{ padding: "20px" }}>
            <Row gutter={16}>
                <Col span={6}>
                    <Statistic title="Currently Managed Assets" value={analytics["active_assets"]} />
                </Col>
                <Col span={6}>
                    <Statistic title="Holding Assets Worth" value={analytics["depreciated_assets_value"]} suffix="Rs" precision={2} />
                </Col>
                <Col span={6}>
                    <Statistic title="Assets Pending Approval" value={analytics["pending_approval"]} />
                </Col>
                <Col span={6}>
                    <a onClick={() => setOpenExtraText(extractWarrantyData(analytics["warranty_data"]))}><Statistic title="Warranty Expiry in 30 days" value={analytics["upcoming_warranty_expiry"]} /></a>
                </Col>
            </Row>
            <Divider orientation="left">
                Asset Inventory
                &nbsp;
                &nbsp;
                <Button type="primary" onClick={() => { setId(null); setOpen(!open); }}>Add</Button>
                &nbsp;
                &nbsp;
                <AssetFilter assetFilter={assetFilter} setAssetFilter={setAssetFilter} refresh={refresh} setRefresh={setRefresh} />
                &nbsp;
                &nbsp;
                <Button type="primary" onClick={() => {
                    if (assetFilter != null) {
                        Modal.confirm({
                            title: 'Confirm',
                            content: <>
                                You have applied custom filter to the data. Do you want to export data using this filter?
                                <br/>
                                <br/>
                                <Collapse
                                    size="small"
                                    items={[
                                        {
                                            key: '1',
                                            label: 'Filter Mode',
                                            children: <div style={{backgroundColor: "#031927", color: "#FFFDFD"}}>
                                                <code >
                                                    {JSON.stringify(assetFilter)}
                                                </code>
                                            </div>,
                                        },
                                    ]}
                                />
                            </>,
                            footer: (_, { OkBtn, CancelBtn }) => (
                                <>
                                    <Button onClick={() => exportData(true)}>Export without filter</Button>
                                    <CancelBtn />
                                    <Button onClick={() => exportData()} >Export</Button>
                                </>
                            ),
                        });
                        return
                    }
                    exportData();
                    notification.info({
                        message: 'Exporting Data',
                        description: 'Starting to export data to CSV file using current filter. This may take a while.',
                    })

                }}>Export</Button>
                &nbsp;
                &nbsp;
                <Switch checkedChildren="Full" unCheckedChildren="Compact" value={fullMode} onChange={() => setFullMode(!fullMode)} />
            </Divider>
            <Table
                dataSource={data.data}
                size="small"
                style={{ width: "100%", height: "100%", overflow: 'auto' }}
                columns={columns}
                pagination={{
                    total: (data.pagination != null) ? data.pagination.total_items : 0,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['1', '10', '20', '50', '100'],
                    pageSize: pageConfig?.pageSize,
                    onChange: (page, pageSize) => {
                        setPageConfig({ page: page, pageSize: pageSize })
                        fetchAssets(page, pageSize)
                    }
                }}
            />
            <AddOrModifyAsset open={open} setOpen={setOpen} id={id} setId={setId} refresh={refresh} setRefresh={setRefresh} />
            <Drawer
                title="View Full Information"
                placement="bottom"
                closable={true}
                height={500}
                onClose={() => setOpenExtraText(false)}
                open={(openExtraText !== false)}
            >
                <Typography.Text>
                    {openExtraText ? openExtraText : "No data available"}
                </Typography.Text>
            </Drawer>
        </div>
    )
}

export default AssetAdmin
