import React, { useState } from 'react';
import { Button, Modal, Form, Input, message, Typography } from 'antd';
import axios from 'axios';
import { backend_url } from '../../config/constants';
const { Title, Text } = Typography;

const PasswordReset = () => {
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        },
    }

    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
        setVisible(true);
    }
    const onClose = () => {
        setVisible(false);
    }
    const [form] = Form.useForm();

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const onFinish = () => {
        form.validateFields().then(() => {
            const values = form.getFieldsValue();
            if (values.new_password !== values.confirm_password) {
                message.error('Passwords do not match!');
                return;
            }
            const form_data = new FormData()
            form_data.append('password', values.old_password)
            form_data.append('new_password', values.new_password)
            form_data.append('confirm_password', values.confirm_password)
            axios({
                method: 'POST',
                url: `${backend_url}/change_password.php`,
                data: form_data,
                headers: auth.headers
            }).then((res) => {
                if (res.status === 200) {
                    message.success('Password changed successfully!');
                    form.resetFields();
                    setVisible(false);
                }
            }).catch((err) => {
                if (err.response) {
                    message.error(err.response.data.message);
                } else {
                    message.error('An error occurred! Please try again later!');
                }
            }); 
        }).catch((errorInfo) => {
            message.error('Please fill in all fields!');
        });
    }

    return (
        <>
            <Button type="primary" onClick={showDrawer} block>
                Change Password
            </Button>

            <Modal title="Password Reset" visible={visible} onOk={onFinish} onCancel={onClose}>
                <Form layout="vertical"
                    form={form}
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{ remember: true }}
                >
                    <Form.Item
                        label="Old Password"
                        name="old_password"
                        rules={[{ required: true, message: 'Please input your old password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="New Password"
                        name="new_password"
                        rules={[{ required: true, message: 'Please input your new password!' }, { min: 8, message: 'Password must be at least 8 characters long!'}]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        label="Confirm Password"
                        name="confirm_password"
                        rules={[{ required: true, message: 'Please input your confirm password!'}, { min: 8, message: 'Password must be at least 8 characters long!'}]}
                    >
                        <Input.Password />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};


export { PasswordReset };