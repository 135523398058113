import React, { useEffect, useState } from 'react'
import { Drawer, Button, Space, Form, Input, Row, Card, Col, Select, Avatar, Typography, Cascader, DatePicker, Tag, message, Modal, Switch } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { UserOutlined, ShoppingOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import axios from 'axios';
import dayjs from 'dayjs';
import { backend_url } from '../config/constants';
import FileUploadWidget from './FileUploadWidget';

const AddOrModifyAsset = ({ open, setOpen, id, setId, refresh, setRefresh }) => {
    const [form] = useForm();
    const [updateAssetCodeForm] = useForm();
    const [categories, setCategories] = useState([]);
    const [filteredCategories, setFilteredCategories] = useState([]);
    const [location, setLocation] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [user, setUser] = useState([]);
    const [purchaseReceipt, setPurchaseReceipt] = useState(null);
    const [warrantyReceipt, setWarrantyReceipt] = useState(null);
    const [vendor, setVendor] = useState([]);
    const formData = new FormData();
    const onClose = () => {
        form.resetFields();
        setId(null);
        setOpen(false);
    };
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }

    const AssetCodeModificationWizard = (id) => {
        setOpenDrawer(true);
        updateAssetCodeForm.setFieldsValue({ asset_code: id.asset_code });
    }

    useEffect(() => {
        axios.get(`${backend_url}/users.php?status=active&group=user`, auth).then((response) => {
            let data = []
            response.data.users.forEach((user) => {
                data.push({
                    value: user.email,
                    username: user.username,
                    label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Avatar size="small" icon={<UserOutlined />} />
                        &nbsp;
                        &nbsp;
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <><Typography.Text>{user.username}</Typography.Text></>
                            <Typography.Text>{user.location}</Typography.Text>
                        </div>
                    </div>
                });
            });
            setUser(data);
        }).catch((error) => {
            console.error(error);
            message.error('Error fetching users');
        });

        axios.get(`${backend_url}/vendors.php?status=1`, auth).then((response) => {
            let state = [];
            let temp = []
            response.data.forEach((element) => {
                state.push({
                    label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                        <Avatar size="small" icon={<ShoppingOutlined />} />
                        &nbsp;
                        &nbsp;
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <><Typography.Text>{element.name}</Typography.Text></>
                            <Typography.Text style={{ fontSize: "12px", opacity: ".8" }}>{element.address}, {element.location.location}, {element.location.state}</Typography.Text>
                        </div>
                    </div>,
                    filter: [element.name, element.location.location, element.email],
                    value: element.id,
                })
                temp.push(element.state)
            })
            setVendor(state);
        }).catch((error) => {
            message.error('Error fetching vendors');
        });

        axios.get(`${backend_url}/location.php?status=active`, auth).then((response) => {
            let option = []
            response.data.forEach(location => {
                const found = option.find(option => option.value === location.state)
                if (!found) {
                    option.push({
                        value: location.state,
                        label: location.state,
                        children: [
                            {
                                value: location.uuid,
                                label: location.location,
                            }
                        ]
                    })
                } else {
                    option.forEach(option => {
                        if (option.value === location.state) {
                            option.children.push({
                                value: location.uuid,
                                label: location.location,
                            })
                        }
                    })
                }
                setLocation(option)

                axios.get(`${backend_url}/auth.php`, auth).then((response) => {
                    const state = response.data.user.location.state;
                    const city = response.data.user.location.location;
                    option.forEach((value) => {
                        if (value.label === state) {
                            value.children.forEach((location) => {
                                if (location.label === city) {
                                    if (response.data.user.access_level === 0) {
                                        setLocation([
                                            {
                                                value: value.value,
                                                label: value.label,
                                                children: [
                                                    {
                                                        value: location.value,
                                                        label: location.label,
                                                    }
                                                ]
                                            }
                                        ])
                                    }
                                    form.setFieldsValue({ location: [value.value, location.value] });
                                }
                            })
                        }
                        if (response.data.user.access_level === 0) {
                            form.setFieldsValue({ asset_user_email: response.data.user.email })
                            setUser([{
                                value: response.data.user.email,
                                username: response.data.user.username,
                                label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                    <Avatar size="small" icon={<UserOutlined />} />
                                    &nbsp;
                                    &nbsp;
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <><Typography.Text>{response.data.user.username}</Typography.Text></>
                                        <Typography.Text>{response.data.user.location.location}, {response.data.user.location.state}</Typography.Text>
                                    </div>
                                </div>
                            }]);
                        }
                    });

                }).catch((error) => {
                    message.error('Error fetching user location');
                });
            })
        }).catch((error) => {
            message.error('Error fetching location');
        });

        axios.get(`${backend_url}/categories.php?active=true`, auth).then((response) => {
            let data = []
            response.data.forEach((category) => {
                let children = []
                category.children.forEach((child) => {
                    children.push({
                        value: child.id,
                        label: child.name,
                        zones: child.zones
                    });
                });
                data.push({
                    value: category.id,
                    label: category.name,
                    children: children || [],
                    zones: category.zones
                });
            });
            data = data.filter((category) => category.children.length > 0);
            setCategories(data);
        }).catch((error) => {
            message.error('Error fetching categories');
        });

        if (id) {
            const data = id
            form.setFieldsValue({
                asset_name: data.asset_name,
                asset_price: data.asset_price,
                asset_depreciation: data.asset_depreciation,
                asset_bill_number: data.asset_bill_number,
                asset_voucher_code: data.asset_voucher_code,
                asset_description: data.asset_description,
                asset_remark: data.asset_remark,
                asset_bought_on: dayjs(data.asset_bought_on),
                asset_warranty_expiry: dayjs(data.asset_warranty_expiry),
                asset_user_email: data.asset_user_email,
                asset_vendor: data.asset_vendor_uid,
                asset_category: [data.asset_parent_category_uid, data.asset_category_uid],
                asset_location: [data.asset_location_state, data.asset_location_uuid],
                asset_code: data.asset_code,
                asset_disposal_date: data.asset_disposal_date ? dayjs(data.asset_disposal_date) : null,
                asset_disposal_vendor: data.asset_disposal_vendor_uid ? data.asset_disposal_vendor_uid : null,
            });
        }
    }, [id])

    return (
        <Drawer
            title="Create and modify asset"
            width={1000}
            onClose={onClose}
            open={open}
            styles={{
                body: {
                    paddingBottom: 80,
                },
            }}
            extra={
                <Space>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button onClick={() => {
                        form.submit();
                    }} type="primary">
                        Submit
                    </Button>
                </Space>
            }
        >
            <Form form={form} layout="vertical" name="asset_manager"
                onFinish={(values) => {
                    const formData = new FormData();
                    formData.append('asset_bill_location', purchaseReceipt);
                    formData.append('asset_warranty_location', warrantyReceipt);
                    for (let key in values) {
                        if (key === 'asset_category' || key === 'asset_location') {
                            formData.append(key, values[key][1]);
                            continue;
                        }
                        if (key === 'asset_warranty_expiry' || key === 'asset_bought_on') {
                            formData.append(key, values[key].format('YYYY-MM-DD'));
                            continue;
                        }
                        if (key === 'asset_disposal_date') {
                            if (values[key] && (values[key] !== null || values[key] !== undefined)) {
                                formData.append(key, values[key].format('YYYY-MM-DD'));
                            }
                            continue;
                        }
                        if (key === 'asset_disposal_vendor') {
                            console.log(values[key]);
                        }
                        if (key === 'asset_disposal_vendor') {
                            if (values[key] == null) continue;
                            formData.append(key, values[key]);
                            continue;
                        }
                        formData.append(key, values[key]);
                    }
                    if (id) {
                        formData.append('update', "true");
                    }
                    axios.post(`${backend_url}/products.php`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }).then((response) => {
                        message.success('Asset added successfully');
                        onClose();
                        setRefresh(!refresh);
                        form.resetFields();
                    }).catch((error) => {
                        if (error.response) {
                            message.error(error.response.data.message);
                        }
                    });
                }}
            >
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_name"
                            label="Asset name"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter an asset name',
                                }
                            ]}
                        >
                            <Input placeholder="Enter Asset Name" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_code"
                            label={<>
                                Asset Code&nbsp;&nbsp;
                                {id ? <a onClick={() => AssetCodeModificationWizard(id)}>Change Asset Code?</a> : null}
                            </>}
                            disabled={id ? true : false}
                            suffix={<>OI/</>}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter an asset code',
                                }
                            ]}
                        >
                            <Input disabled={id ? true : false} placeholder="Enter Asset Name" prefix={<>OI/</>} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_price"
                            label="Enter Price"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a price',
                                },
                                {
                                    pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Please enter a valid price'
                                }
                            ]}
                        >
                            <Input placeholder="Enter Price" prefix={<>₹</>} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_depreciation"
                            label="Depreciation Rate"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a depreciation rate',
                                },
                                {
                                    pattern: /^[0-9]+(\.[0-9]{1,2})?$/,
                                    message: 'Please enter a valid depreciation rate'
                                },
                            ]}
                        >
                            <Input placeholder="Enter Depreciation Rate" suffix={<>%</>} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_bill_number"
                            label="Bill Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a bill number',
                                }
                            ]}
                        >
                            <Input placeholder="Enter Bill Number" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_voucher_code"
                            label="Voucher Number"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter a voucher code ',
                                }
                            ]}
                        >
                            <Input placeholder="Enter Voucher Code" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_location"
                            label="Select Location"
                        >
                            <Cascader
                                placeholder="Select a location" options={location}
                                onChange={(value) => {
                                    if (value && value.length === 2) {
                                        const location = value[1];
                                        let filtered = []
                                        categories.forEach((category) => {
                                            category.children.forEach((child) => {
                                                child.zones.forEach((zone) => {
                                                    if (zone.id === location) {
                                                        const found = filtered.find((element) => element.value === category.value);
                                                        if (!found) {
                                                            filtered.push({
                                                                value: category.value,
                                                                label: category.label,
                                                                children: [
                                                                    {
                                                                        value: child.value,
                                                                        label: child.label,
                                                                        zones: child.zones
                                                                    }
                                                                ]
                                                            });
                                                        } else {
                                                            filtered.forEach((element) => {
                                                                if (element.value === category.value) {
                                                                    element.children.push({
                                                                        value: child.value,
                                                                        label: child.label,
                                                                        zones: child.zones
                                                                    });
                                                                }
                                                            });
                                                        }
                                                    }
                                                })

                                            })
                                        });
                                        console.log(filtered, "SDfasdfsdf");
                                        setFilteredCategories(filtered);
                                    }
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_category"
                            label="Select Category"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a category',
                                }
                            ]}
                        >
                            <Cascader
                                placeholder="Select a category"
                                options={filteredCategories}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_user_email"
                            label="Asset User"
                        >
                            <Select
                                style={{ height: '60px' }}
                                placeholder="Select a user"
                                options={user}
                                allowClear
                                showSearch
                                filterOption={(input, option) => {
                                    return option.username.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_vendor"
                            label="Vendor"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a vendor',
                                }
                            ]}
                        >
                            <Select
                                style={{ height: '60px' }}
                                placeholder="Select a vendor"
                                options={vendor}
                                showSearch
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_disposal_date"
                            label={
                                <>Asset Disposal Date&nbsp;&nbsp;<Tag color="processing" size="small">Optional</Tag></>
                            }
                        >
                            <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_disposal_vendor"
                            label={
                                <>Disposal Vendor&nbsp;&nbsp;<Tag color="processing" size="small">Optional</Tag></>
                            }
                        >
                            <Select
                                style={{ height: '60px' }}
                                placeholder="Select a vendor"
                                options={vendor}
                                allowClear
                                showSearch
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_description"
                            label="Description"
                        >
                            <Input.TextArea placeholder="Enter Description" />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_remark"
                            label="Remark"
                        >
                            <Input.TextArea placeholder="Enter Remark" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            name="asset_bought_on"
                            label="Purchase Date"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a date',
                                }
                            ]}
                        >
                            <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name="asset_warranty_expiry"
                            label="Warranty Expiry Date"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please select a date',
                                }
                            ]}
                        >
                            <DatePicker format={'DD/MM/YYYY'} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item
                            label="Bill Document"
                        >
                            <FileUploadWidget file={purchaseReceipt} setFile={setPurchaseReceipt} form={formData} name={"asset_bill_location"} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            label="Warranty Document"
                        >
                            <FileUploadWidget file={warrantyReceipt} setFile={setWarrantyReceipt} form={formData} name={"asset_warranty_location"} />
                        </Form.Item>
                    </Col>
                </Row>
                {
                    id ? <Row gutter={16}>
                        <Col span={12}>
                            <Card title="Purchase Document">
                                <a href={`${backend_url}/uploads/${id.asset_bill_location}`} target="_blank" rel="noreferrer">View Document<br />{id.asset_bill_location}</a>
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card title="Warranty Document">
                                <a href={`${backend_url}/uploads/${id.asset_warranty_location}`} target="_blank" rel="noreferrer">View Document<br />{id.asset_warranty_location}</a>
                            </Card>
                        </Col>
                    </Row> : null
                }
            </Form>
            <Drawer
                title="Asset Code Modification Wizard"
                width={1000}
                onClose={() => setOpenDrawer(false)}
                open={openDrawer}
                styles={{
                    body: {
                        paddingBottom: 80,
                    },
                }}
                extra={
                    <Space>
                        <Button onClick={() => setOpenDrawer(false)}>Cancel</Button>
                        <Button onClick={() => {
                            updateAssetCodeForm.submit();
                        }} type="primary">
                            Submit
                        </Button>
                    </Space>
                }
            >
                <Form form={updateAssetCodeForm} layout="vertical" name="asset_manager"
                    onFinish={(values) => {
                        axios.put(`${backend_url}/products.php`, {
                            asset_code: values.asset_code,
                            new_asset_code: values.new_asset_code,
                            dupilcate: values.dupilcate,
                            action: "update_asset_code"
                        }, auth).then((response) => {
                            message.success('Asset code updated successfully');
                            setOpenDrawer(false);
                            setRefresh(!refresh);
                            onClose();
                        }).catch((error) => {
                            if (error.response) {
                                message.error(error.response.data.message);
                            }
                        });
                    }}
                >
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="asset_code"
                                label="Current Asset Code"
                                prefix={<>OI/</>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter an asset code',
                                    }
                                ]}
                            >
                                <Input disabled placeholder="Enter Asset Code" value={id} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="new_asset_code"
                                label="Enter New Asset Code"
                                prefix={<>OI/</>}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter an asset code',
                                    }
                                ]}
                            >
                                <Input placeholder="Enter Asset Code" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item
                                name="dupilcate"
                                label="Duplicate Asset"
                            >
                                <Switch
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    defaultValue={false}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </Drawer>
    )
}

export default AddOrModifyAsset;
