import React, { useEffect } from 'react';
import { Form, Input, Button, message, Alert } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { backend_url } from '../config/constants';

const LoginForm = ({ msg = null }) => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();

    // if its http go to https:
    if (window.location.protocol === "http:" && window.location.hostname !== "localhost") {
        window.location.href = window.location.href.replace("http", "https");
    }

    const onFinish = async (values) => {
        const key = 'updatable';
        messageApi.loading({ content: 'Logging you in...', key });
        try {
            const form_data = new FormData()
            form_data.append('email', values.email)
            form_data.append('password', values.password)
            axios.post(`${backend_url}/auth.php`, form_data).then((res) => {
                if (res.status === 200) {
                    messageApi.success({ content: 'Login successful!', key, duration: 2 });
                    setTimeout(() => {
                        sessionStorage.setItem('token', res.data.token);
                        sessionStorage.setItem('expires', res.data.expires);
                        localStorage.setItem('userdata', JSON.stringify(res.data.user));
                        navigate('/dashboard');
                    }, 2000);
                } else {
                    messageApi.error({ content: 'Login failed!', key, duration: 2 });
                }
            }).catch((err) => {
                messageApi.error({ content: 'Login failed!', key, duration: 2 });
            });
        } catch (error) {
            messageApi.error({ content: 'Login failed due to an exception!', key, duration: 2 });
        }
    }; 

    useEffect(() => {
        document.title = "Login";
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundImage: `url('https://images.unsplash.com/photo-1718081591976-64a345c4f92e?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                backgroundSize: 'cover',
            }}
        >
            {contextHolder}
            <div style={{ width: '300px', padding: '20px', borderRadius: '.75rem', background: '#fff' }}>
                <h1 style={{ textAlign: 'center' }}>Login</h1>
                {msg != null && <Alert type="error" message={msg} banner />}
                <br />
                <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please enter your Email!' }]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                        <Input.Password placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Link to="/forgot-password">Forgot Password?</Link>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            Log In
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default LoginForm;