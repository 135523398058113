import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';
import axios from 'axios';

const QuarterlySpendingChart = ({ quarterlySpendingData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'line',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Quarter'
        }
      },
      yaxis: {
        title: {
          text: 'Total Spending'
        }
      },
      title: {
        text: 'Quarterly Spending by Location',
        align: 'left'
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter: function (value) {
            return "Rs. " + value; // Format the tooltip value as needed
          }
        }
      }
    }
  });

  useEffect(() => {
    if (quarterlySpendingData && quarterlySpendingData.length > 0) {
      const locations = {};
      let categories = [];

      // Create a map to store data for each location
      quarterlySpendingData.forEach(item => {
        const category = `Q${item.quarter} ${item.year}`;
        if (!categories.includes(category)) {
          categories.push(category);
        }
        if (!locations[item.location]) {
          locations[item.location] = {};
        }
        locations[item.location][category] = item.total;
      });

      // Generate a continuous range of quarters for the past 5 years (including the current year)
      const currentYear = new Date().getFullYear();
      for (let year = currentYear; year >= currentYear - 5; year--) {
        for (let quarter = 4; quarter >= 1; quarter--) {
          const category = `Q${quarter} ${year}`;
          if (!categories.includes(category)) {
            categories.push(category);
          }
        }
      }

      // Sort the categories based on year and quarter
      categories = categories.sort((a, b) => {
        const [quarterA, yearA] = a.split(' ');
        const [quarterB, yearB] = b.split(' ');
        if (yearA === yearB) {
          return parseInt(quarterA.substring(1)) - parseInt(quarterB.substring(1));
        } else {
          return yearA - yearB;
        }
      });

      // Fill missing data with zeros for each location
      const series = Object.keys(locations).map(location => ({
        name: location,
        data: categories.map(category => locations[location][category] || 0) // Fill missing data with 0
      }));

      setChartData(prevState => ({
        ...prevState,
        series: series,
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: categories
          }
        }
      }));
    }
  }, [quarterlySpendingData]);

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default QuarterlySpendingChart;
