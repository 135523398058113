import { Link, useLocation } from 'react-router-dom';
import { HomeOutlined, UsergroupAddOutlined, LogoutOutlined, PushpinOutlined, PoundCircleOutlined, SettingOutlined, PartitionOutlined } from '@ant-design/icons';
import { Menu, Space, Typography } from 'antd';

const NavBar = ({ collapsed }) => {
    const x = useLocation().pathname;
    const access_level = JSON.parse(localStorage.getItem('userdata')).access_level;
    if (access_level === 1 || access_level === 2) {
        return <>
            {
                !collapsed && <>
                    <Typography.Title level={3} style={{ margin: 0, color: "white" }}>
                        Asset Management System
                    </Typography.Title>
                    <Typography.Text style={{ color: "white", textAlign: 'right', width: "100%" }}>Oasis</Typography.Text></>
            }
            <br />
            <br />
            {AdminLevelNavBar(x)}
            
        </>;
    } else {
        return <>
            <Typography.Title level={3} style={{ margin: 0, color: "white" }}>
                Asset Management System
            </Typography.Title>
            <br />
            <br />
            {UserLevelNavBar(x)}
            {/* <Space /> */}
            fdgjsdlfghhsdjfghjk
        </>;
    }
}

const AdminLevelNavBar = (x) => {
    const url = ["/dashboard", "/locations", "/assets", "/category", "/vendors", "/settings", "/"]
    const access_level = JSON.parse(localStorage.getItem('userdata')).access_level;
    if (access_level === 2) {
        url.splice(2, 0, "/users");
    }
    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={(url.indexOf(x) + 1).toString()}>
            <Menu.Item key="1" icon={<HomeOutlined />}>
                <Link to={`/dashboard`}>Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PushpinOutlined />}>
                <Link to={`/locations`}>Locations</Link>
            </Menu.Item>
            {
                access_level === 2 && <Menu.Item key="3" icon={<UsergroupAddOutlined />}>
                    <Link to={`/users`}>User Management</Link>
                </Menu.Item>
            }
            <Menu.Item key="4" icon={<PoundCircleOutlined />}>
                <Link to={`/assets`}>Asset Management</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<PartitionOutlined />}>
                <Link to={`/category`}>Category</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<HomeOutlined />}>
                <Link to={`/vendors`}>Vendors</Link>
            </Menu.Item>
            <Menu.Item key="7" icon={<SettingOutlined />}>
                <Link to={`/settings`}>Settings</Link>
            </Menu.Item>
            <Menu.Item key="8" icon={<LogoutOutlined />}>
                <Link to={`/`}>Logout</Link>
            </Menu.Item>
        </Menu>
    )
}

const UserLevelNavBar = (x) => {
    const url = ["/dashboard", "/category", "/vendors", "/assets", "/settings", "/"]
    return (
        <Menu theme="dark" mode="inline" defaultSelectedKeys={(url.indexOf(x) + 1).toString()}>
            <Menu.Item key="1" icon={<HomeOutlined />}>
                <Link to={`/dashboard`}>Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<PartitionOutlined />}>
                <Link to={`/category`}>Category</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<HomeOutlined />}>
                <Link to={`/vendors`}>Vendors</Link>
            </Menu.Item>
            <Menu.Item key="4" icon={<PoundCircleOutlined />}>
                <Link to={`/assets`}>Assets</Link>
            </Menu.Item>
            <Menu.Item key="5" icon={<SettingOutlined />}>
                <Link to={`/settings`}>Settings</Link>
            </Menu.Item>
            <Menu.Item key="6" icon={<LogoutOutlined />}>
                <Link to={`/`}>Logout</Link>
            </Menu.Item>
        </Menu>
    )
}

export default NavBar;