import React, { useEffect, useState } from 'react';
import { Avatar, Button, Layout, Spin, Table, Popconfirm, Tag, Space, Typography, notification, Result, Drawer, Form, Input, Select, Col, Row } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined, PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import NavBar from '../components/Navbar';
import axios from 'axios';
import { backend_url, childRow } from '../config/constants';

const { Header, Sider, Content } = Layout;
const { Option } = Select;

const LocationManagement = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const [api, contextHolder] = notification.useNotification();
    const [form] = Form.useForm();
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [open, setOpen] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [loading, setLoading] = useState(true);

    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    };

    useEffect(() => {
        document.title = "Location Management";
        fetchLocations();
    }, [open]);

    const fetchLocations = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${backend_url}/location.php`, auth);
            const stateFilters = [...new Set(response.data.map(item => item.state))].map(state => ({ text: state, value: state }));
            setDataSource(response.data.map(item => ({
                ...item,
                key: item.uuid
            })));
        } catch (error) {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async (uuid) => {
        try {
            await axios.delete(`${backend_url}/location.php`, { data: { uuid }, ...auth });
            api.success({
                message: 'Location deleted!',
                description: 'Location has been deleted successfully!',
            });
            fetchLocations();
        } catch (error) {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
        }
    };

    const handleFormSubmit = async (values) => {
        try {
            if (selectedLocation) {
                await axios.put(`${backend_url}/location.php`, { ...values, uuid: selectedLocation.uuid }, auth);
                api.success({
                    message: 'Location updated!',
                    description: 'Location has been updated successfully!',
                });
            } else {
                await axios.post(`${backend_url}/location.php`, values, auth);
                api.success({
                    message: 'Location added successfully!',
                    description: 'Location has been added successfully!',
                });
            }
            onClose();
            fetchLocations();
        } catch (error) {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
        }
    };

    const showDrawer = () => setOpen(true);

    const onClose = () => {
        form.resetFields();
        setSelectedLocation(null);
        setOpen(false);
    };

    const columns = [
        {
            title: 'UUID',
            dataIndex: 'uuid',
            key: 'uuid',
            render: text => <a>{text}</a>,
        },
        {
            title: 'Location',
            dataIndex: 'location',
            key: 'location',
            sorter: (a, b) => a.location.localeCompare(b.location),
        },
        {
            title: 'State',
            dataIndex: 'state',
            key: 'state',
            sorter: (a, b) => a.state.localeCompare(b.state),
            filters: [...new Set(dataSource.map(item => item.state))].map(state => ({ text: state, value: state })),
            onFilter: (value, record) => record.state.includes(value),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            sorter: (a, b) => a.status - b.status,
            render: value => value === 1 ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>,
            filters: [
                { text: 'Active', value: 1 },
                { text: 'Inactive', value: 0 },
            ],
            onFilter: (value, record) => record.status === value,
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (text, record) => (
                <Space size="middle">
                    <Button type="primary" icon={<EditOutlined />} onClick={() => {
                        setSelectedLocation(record);
                        form.setFieldsValue(record);
                        showDrawer();
                    }} />
                    <Popconfirm
                        title="Are you sure you want to delete this location?"
                        onConfirm={() => handleDelete(record.uuid)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button type="danger" icon={<DeleteOutlined />} />
                    </Popconfirm>
                </Space>
            ),
        }
    ];

    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            {contextHolder}
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{ fontSize: '16px', width: 64, height: 64 }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>Location Management</Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userData.username}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px', padding: '0px 16px', overflowY: 'auto', backgroundColor: "white", borderRadius: ".75rem" }}>
                    <Drawer
                        title="Create and modify location"
                        width={720}
                        onClose={onClose}
                        open={open}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button onClick={() => form.submit()} type="primary">Submit</Button>
                            </Space>
                        }
                    >
                        <Form
                            layout="vertical"
                            form={form}
                            onFinish={handleFormSubmit}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="location"
                                        label="Location Name"
                                        rules={[{ required: true, message: 'Please enter a location' }]}
                                    >
                                        <Input placeholder="Please enter location" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="state"
                                        label="State"
                                        rules={[{ required: true, message: 'Please enter location state' }]}
                                    >
                                        <Input placeholder="Please enter location state" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Location Status"
                                        rules={[{ required: true, message: 'Select a status' }]}
                                    >
                                        <Select placeholder="Select a status">
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {loading ? (
                        <Spin tip="Loading...">
                            <Table columns={columns} dataSource={[]} />
                        </Spin>
                    ) : (
                        <Table
                            title={() => (
                                <div style={childRow}>
                                    <Typography.Title level={4}>Location Management</Typography.Title>
                                    <Button type="primary" onClick={showDrawer}>
                                        <PlusOutlined /> Add Location
                                    </Button>
                                </div>
                            )}
                            columns={columns}
                            dataSource={dataSource}
                        />
                    )}
                </Content>
            </Layout>
        </Layout>
    );
};

export default LocationManagement;
