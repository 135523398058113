import { Typography, Button, Layout } from 'antd';
import Password from 'antd/es/input/Password';
import React from 'react';
import { PasswordReset } from './functions/PasswordChange';
import { childBtnCol, childCol1, GetInfoWidget, parentStyle } from '../config/constants';
const { Content } = Layout;

const AdminAccountSettings = () => {


    const getAccountType = () => {
        const access_level = JSON.parse(localStorage.getItem('userdata')).access_level;
        if (access_level === 1) {
            return "Manager";
        } else if (access_level === 2) {
            return "Administrator";
        } else {
            return "User";
        }
    }

    return (
        <div style={{ margin: '24px 16px 0 16px', overflowY: 'auto' }}>
            <div style={parentStyle}>
                <div style={childCol1}>
                    <GetInfoWidget TextTitle={"Change Password"} Paragraph={"Change your account password"} />
                </div>
                <div style={childBtnCol}>
                    {PasswordReset()}
                </div>
            </div>
            <div style={parentStyle}>
                <div style={childCol1}>
                    <GetInfoWidget TextTitle={"Account Access Level"} Paragraph={"Change the access level for your account. This is managed by your organisation administrator"} />
                </div>
                <div style={childBtnCol}>
                    <Button block >{getAccountType()}</Button>
                </div>
            </div>
        </div>
    )
}

export default AdminAccountSettings