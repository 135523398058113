import React, { useEffect } from 'react'
import { Result } from 'antd';

const PageNotFound = () => {
    useEffect(() => {
        document.title = "404 - Page Not Found";
    }, []);
    
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundImage: `url('https://source.unsplash.com/random/1920x1080?landscape')`,
                backgroundSize: 'cover',
            }}>
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                style={{
                    backgroundColor: 'rgba(255, 255, 255, .9)',
                    borderRadius: '.75rem',
                    width: '75vw',
                    height: '75vh',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', 
                    justifyContent: 'center',
                }}
            />
        </div>
    )
}

export default PageNotFound
