import { Typography, Layout, Button } from 'antd';
import React from 'react';
import { PasswordReset } from './functions/PasswordChange';
const { Content } = Layout;
const { Text, Title } = Typography;

const UserAccountSettings = () => {
    const parentStyle = { display: "flex", width: "100%", padding: "10px 5px" };
    const childCol1 = { margin: 0, width: "100%", display: 'flex', flexDirection: 'column', alignItems: "start", justifyContent: "space-between" }
    const childBtnCol = { width: "200px", margin: "auto 30px", padding: "auto" }

    const GetInfoWidget = ({ TextTitle, Paragraph }) => {
        return (
            <div style={childCol1}>
                <Title level={4} style={{ margin: "0" }}>
                    {TextTitle}
                </Title>
                <Text style={{ marginTop: "5px" }}>
                    {Paragraph}
                </Text>
            </div>
        )
    }

    const getAccountType = () => {
        const access_level = JSON.parse(localStorage.getItem('userdata')).access_type;
        if (access_level === 1) {
            return "Manager";
        } else if (access_level === 2) {
            return "Administrator";
        } else {
            return "User";
        }
    }

    return (
        <div style={{ margin: "20px 0" }}>
            <Content style={{ margin: '24px 16px 0 16px', overflowY: 'auto' }}>
                <div style={parentStyle}>
                    <div style={childCol1}>
                        <GetInfoWidget TextTitle={"Change Password"} Paragraph={"Change your account password"} />
                    </div>
                    <div style={childBtnCol}>
                        {PasswordReset()}
                    </div>
                </div>
                <div style={parentStyle}>
                    <div style={childCol1}>
                        <GetInfoWidget TextTitle={"Account Access Level"} Paragraph={"Change the access level for your account. This is managed by your organisation administrator"} />
                    </div>
                    <div style={childBtnCol}>
                        <Button block >{getAccountType()}</Button>
                    </div>
                </div>
            </Content>
        </div>
    )
}

export default UserAccountSettings