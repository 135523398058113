import React, { useEffect } from 'react';
import { Form, Input, Button, message, Alert } from 'antd';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { backend_url } from '../config/constants';

const ResetPassword = ({ msg = null }) => {
    const navigate = useNavigate();
    const [messageApi, contextHolder] = message.useMessage();
    const [stage, setStage] = React.useState(0);

    const onFinish = async (values) => {
        const key = 'updatable';
        messageApi.loading({ content: 'Confirming your reset request', key });
        const link = (stage === 0) ? '?method=initiate' : '?method=reset';
        const form_data = new FormData()
        form_data.append('email', values.email)
        if (stage === 1) {
            form_data.append('code', values.code)
            form_data.append('password', values.password)
            form_data.append('confirm_password', values.confirm_password)
        }
        axios.post(`${backend_url}/forgot_password.php${link}`, form_data).then((res) => {
            if (res.status === 200) {
                messageApi.success({ content: res.data.message, key, duration: 2 });
                setTimeout(() => {
                    if (stage === 0) {
                        setStage(1);
                    } else {
                        navigate('/');
                    }
                }, 2000);
            } else {
                messageApi.error({ content: res.data.message, key, duration: 2 });
            }
        }).catch((err) => {
            if (err.response) {
                messageApi.error({ content: err.response.data.message, key, duration: 2 });
            } else {
                messageApi.error({ content: 'An error occurred!', key, duration: 2 });
            }
        });
    };

    useEffect(() => {
        document.title = "Account Recovery";
    }, []);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundImage: `url('https://images.unsplash.com/photo-1718081591976-64a345c4f92e?q=80&w=3271&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D')`,
                backgroundSize: 'cover',
            }}
        >
            {contextHolder}
            <div style={{ width: '300px', padding: '20px', borderRadius: '.75rem', background: '#fff' }}>
                <h1 style={{ textAlign: 'center' }}>Account Recovery</h1>
                {msg != null && <Alert type="error" message={msg} banner />}
                <br />
                <Form onFinish={onFinish}>
                    <Form.Item
                        name="email"
                        rules={[{ required: true, message: 'Please enter your login!' }]}
                    >
                        <Input placeholder="Login" />
                    </Form.Item>
                    {
                        (stage === 1) ? (
                            <>
                                <Form.Item
                                    name="code"
                                    rules={[{ required: true, message: 'Please enter the code sent to your email!' }]}
                                >
                                    <Input placeholder="Code" />
                                </Form.Item>
                                <Form.Item
                                    name="password"
                                    rules={[{ required: true, message: 'Please enter your new password!' }]}
                                >
                                    <Input.Password placeholder="New Password" />
                                </Form.Item>
                                <Form.Item
                                    name="confirm_password"
                                    rules={[{ required: true, message: 'Please re-enter your new password!' }]}
                                >
                                    <Input.Password placeholder="Re-enter New Password" />
                                </Form.Item>
                            </>
                        ) : null
                    }
                    <Form.Item>
                        <Link to="/">Go back</Link>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
                            {stage === 0 ? 'Send Recovery Email' : 'Reset Password'}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default ResetPassword;