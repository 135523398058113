import { Avatar, Button, Form, message, Modal, Select, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { UserOutlined } from '@ant-design/icons';
import { backend_url } from '../../config/constants';
import axios from 'axios';

export const GroupTransfer = ({ refresh, setRefresh, email }) => {
    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    } 
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = () => {
        setConfirmLoading(true);
        const user = form.getFieldValue('from-group');
        axios.delete(`${backend_url}/users.php?email=${email}&force=true&transfer_to=${user}`, auth).then((response) => {
            message.success('Assets transferred successfully');
            setRefresh(!refresh);
            setOpen(false);
        }).catch((error) => {
            console.error(error);
            message.error('Error transferring assets');
        }).finally(() => {
            setConfirmLoading(false);
        });
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    useEffect(() => {
        axios.get(`${backend_url}/users.php?email=${email}`, auth).then((response) => {
            const user_location = response.data.user.location;
            axios.get(`${backend_url}/users.php?status=active&group=user`, auth).then((response) => {
                let data = []
                response.data.users.forEach((user) => {
                    if (user.email === email) {
                        return;
                    }
                    if (user.user_location !== user_location) {
                        return;
                    }

                    data.push({
                        value: user.email,
                        username: user.username,
                        label: <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                            <Avatar size="small" icon={<UserOutlined />} />
                            &nbsp;
                            &nbsp;
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <><Typography.Text>{user.username}</Typography.Text></>
                                <Typography.Text>{user.location}</Typography.Text>
                            </div>
                        </div>
                    });
                });
                setUsers(data);
            }).catch((error) => {
                console.error(error);
                message.error('Error fetching users');
            });
        }).catch((error) => {
            console.error(error);
            message.error('Error fetching user');
        });
    }, [refresh]);

    return (
        <div>
            <Button type="primary" key="console" onClick={showModal}>
                Group Transfer Assets
            </Button>
            <Modal
                title="Group Transfer Assets"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >
                <Form
                    name="group-transfer"
                    form={form}
                    onFinish={handleOk}
                >
                    <Form.Item
                        label="Transfer to User"
                        name="from-group"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a group'
                            }
                        ]}
                    >
                        <Select placeholder="Select a group" allowClear>
                            {users.map((user) => (
                                <Select.Option key={user.value} value={user.value}>{user.label}</Select.Option>
                            ))}
                        </Select> 
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    )
}
