import { Typography } from 'antd';
const { Title, Text } = Typography;


const backend_url = 'https://assets.oasisindia.org/api/v3';  
const parentStyle = { display: "flex", width: "100%", padding: "10px 5px" };
const childCol1 = { margin: 0, width: "100%", display: 'flex', flexDirection: 'column', alignItems: "start", justifyContent: "space-between" }
const childRow = { margin: 0, width: "100%", display: 'flex', alignItems: "center", justifyContent: "space-between" }
const childBtnCol = { width: "200px", margin: "auto 30px", padding: "auto" }

const GetInfoWidget = ({ TextTitle, Paragraph }) => {
    return (
        <div>
            <Title level={4} style={{ margin: "0" }}>
                {TextTitle}
            </Title>
            <Text style={{ marginTop: "5px" }}>
                {Paragraph}
            </Text>
        </div>
    )
}

export {backend_url, parentStyle, childCol1, childBtnCol, GetInfoWidget, childRow}