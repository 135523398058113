import React, { useEffect } from 'react'
import { Avatar, Button, Layout, Spin, Table, Popconfirm, Tag, Space, Typography, notification, Result, Drawer, Form, Input, Select, Col, Row, Cascader, } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useState } from 'react';
import NavBar from '../components/Navbar';
import axios from 'axios';
import { PlusOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { backend_url, childRow } from '../config/constants';
import { useForm } from 'antd/es/form/Form';
const { Header, Sider, Content } = Layout;
const { TextArea } = Input;
const { Option } = Select;

const VendorManagement = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const [api, contextHolder] = notification.useNotification();
    const [form] = useForm();
    const userName = userData.username;
    const [options, setOptions] = useState([{}]);
    const [selectedVendor, setSelectedVendor] = useState(null);
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        form.resetFields();
        setOpen(false);
    };

    const [ApiCallBackState, setApiCallBackState] = useState(
        <Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'UUID',
                    dataIndex: 'id',
                    key: 'id',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Vendor',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: 'Phone',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                    key: 'location',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: 'Actions',
                    key: 'actions',
                }
            ]} />
        </Spin>
    )


    useEffect(() => {
        document.title = "Vendor Management";
        setApiCallBackState(<Spin tip="Loading...">
            <Table dataSource={[]} columns={[
                {
                    title: 'UUID',
                    dataIndex: 'id',
                    key: 'id',
                    render: text => <a>{text}</a>,
                },
                {
                    title: 'Vendor',
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: 'Phone',
                    dataIndex: 'phone',
                    key: 'phone',
                },
                {
                    title: 'Location',
                    dataIndex: 'location',
                    key: 'location',
                },
                {
                    title: 'Address',
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: 'Actions',
                    key: 'actions',
                }
            ]} />
        </Spin>)
        const auth = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }
        axios.get(`${backend_url}/location.php?status=active`, auth).then((response) => {
            let option = []
            console.log(response.data)
            response.data.forEach(location => {
                const found = option.find(option => option.value === location.state)
                if (!found) {
                    option.push({
                        value: location.state,
                        label: location.state,
                        children: [
                            {
                                value: location.uuid,
                                label: location.location,
                            }
                        ]
                    })
                } else {
                    option.forEach(option => {
                        if (option.value === location.state) {
                            option.children.push({
                                value: location.uuid,
                                label: location.location,
                            })
                        }
                    })
                }
                setOptions(option)
            })
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
        })

        axios.get(`${backend_url}/vendors.php`, auth).then((response) => {
            let state = [];
            let temp = []
            response.data.forEach((element) => {
                if (!temp.includes(element.state)) {
                    state.push({
                        text: element.state,
                        value: element.state
                    })
                    temp.push(element.state)
                }
            })
            setApiCallBackState(
                <Table
                    title={
                        () => <div style={childRow}>
                            <Typography.Title level={4}>Vendor Management</Typography.Title>
                            <Button type="primary" onClick={showDrawer}>
                                <PlusOutlined /> Add Vendor
                            </Button>
                        </div >
                    }
                    dataSource={response.data}
                    columns={[
                        {
                            title: 'UUID',
                            dataIndex: 'id',
                            key: 'id',
                            render: text => <a>{text}</a>,
                        },
                        {
                            title: 'Vendor',
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: 'Email',
                            dataIndex: 'email',
                            key: 'email',
                        },
                        {
                            title: 'Phone',
                            dataIndex: 'phone',
                            key: 'phone',
                        },
                        {
                            title: 'Location',
                            dataIndex: 'location',
                            key: 'location',
                            render: (text, record) => (
                                <span>{record.location.location}, {record.location.state}</span>
                            )
                        },
                        {
                            title: 'Address',
                            dataIndex: 'address',
                            key: 'address',
                        },
                        {
                            title: 'Status',
                            dataIndex: 'status',
                            key: 'status',
                            render: (text) => (
                                <Tag color={text === 1 ? 'green' : 'red'}>{text === 1 ? 'Active' : 'Inactive'}</Tag>
                            ),
                            filters: [
                                { text: 'Active', value: 1 },
                                { text: 'Inactive', value: 0 },
                            ],
                            onFilter: (value, record) => record.status === value,
                        },
                        {
                            title: 'Actions',
                            key: 'actions',
                            render: (text, record) => (
                                <Space size="middle">
                                    <Button type="primary" icon={<EditOutlined />} onClick={() => {
                                        setSelectedVendor(record);
                                        console.log(record)
                                        form.setFieldsValue({
                                            name: record.name,
                                            email: record.email,
                                            phone: record.phone,
                                            location: [record.location.state, record.location.uuid],
                                            status: record.status,
                                            address: record.address

                                        });
                                        showDrawer();
                                    }} />
                                    <Popconfirm
                                        title="Are you sure you want to delete this location?"
                                        onConfirm={() => {
                                            axios.delete(`${backend_url}/vendors.php`, { data: { id: record.id }, ...auth }).then((response) => {
                                                setOpen('')
                                                api.success({
                                                    message: 'Vendor deleted!',
                                                    description: 'Vendor has been deleted successfully!',
                                                });
                                            }).catch((error) => {
                                                api.error({
                                                    message: 'An error occurred!',
                                                    description: 'Please try again later! ' + error,
                                                });
                                                console.log(error)
                                            })
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button type="danger" icon={<DeleteOutlined />} />
                                    </Popconfirm>
                                </Space>
                            ),
                        }
                    ]} />
            )
        }).catch((error) => {
            api.error({
                message: 'An error occurred!',
                description: 'Please try again later! ' + error,
            });
            console.log(error)
            setApiCallBackState(
                <Result
                    title={`An error occurred! ${error.response.data.message}`}
                    subTitle="Sorry, something went wrong. Please try again later!"
                />
            )
        })
    }, [open])

    const [collapsed, setCollapsed] = useState(false);
    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            {contextHolder}
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        Vendor Management
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', padding: '0px 16px 0 16px', overflowY: 'auto', backgroundColor: "white", borderRadius: ".75rem" }}>
                    <Drawer
                        title="Create and modify category"
                        width={720}
                        onClose={onClose}
                        open={open}
                        styles={{
                            body: {
                                paddingBottom: 80,
                            },
                        }}
                        extra={
                            <Space>
                                <Button onClick={onClose}>Cancel</Button>
                                <Button onClick={() => {
                                    form.submit();
                                }} type="primary">
                                    Submit
                                </Button>
                            </Space>
                        }
                    >
                        <Form
                            layout="vertical"
                            name="categories_form"
                            form={form}
                            onFinish={(values) => {
                                if (selectedVendor == null) {
                                    values.location = values.location[1];
                                    axios.post(`${backend_url}/vendors.php`, values, auth).then((response) => {
                                        api.success({
                                            message: 'Vendor added successfully!',
                                            description: 'Vendor has been added successfully!',
                                        });
                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                } else {
                                    values.id = selectedVendor.id;
                                    values.location = values.location[1];
                                    axios.put(`${backend_url}/vendors.php`, values, auth).then((response) => {
                                        api.success({
                                            message: 'Vendor updated!',
                                            description: 'Vendor has been updated successfully!',
                                        });

                                        onClose();
                                    }).catch((error) => {
                                        api.error({
                                            message: 'An error occurred!',
                                            description: 'Please try again later! ' + error,
                                        });
                                        console.log(error)
                                    })
                                }
                            }}
                        >
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="name"
                                        label="Vendor Name"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a name',
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter name" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a valid email address',
                                            },
                                            {
                                                type: 'email',
                                                message: 'Please enter a valid email address'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter email" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="phone"
                                        label="Phone Number"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select a phone_number',
                                            },
                                            {
                                                min: 10,
                                                message: 'Please enter a valid phone number'
                                            },
                                            {
                                                max: 12,
                                                message: 'Please enter a valid phone number'
                                            }
                                        ]}
                                    >
                                        <Input placeholder="Please enter phone number" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item
                                        name="location"
                                        label="Location"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a location',
                                            },
                                        ]}
                                    >
                                        <Cascader options={options} placeholder="Please select a location" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        name="status"
                                        label="Status"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Select a status',
                                            },
                                        ]}
                                    >
                                        <Select placeholder="Select a status">
                                            <Option value={1}>Active</Option>
                                            <Option value={0}>Inactive</Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item
                                        name="address"
                                        label="Address"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter a address',
                                            },
                                        ]}
                                    >
                                        <TextArea showCount maxLength={100} placeholder="Enter a address" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Drawer>
                    {ApiCallBackState}
                </Content>
            </Layout>
        </Layout>
    );
}

export default VendorManagement
