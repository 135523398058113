import './App.css';
import Dashboard from './pages/Dashboard';
import { HashRouter, Route, Routes, } from 'react-router-dom';
import LoginForm from './pages/Login';
import Settings from './pages/Settings';
import ResetPassword from './pages/ForgotPassword';
import UserManagement from './pages/UserManagement';
import LocationManagement from './pages/LocationManagement';
import CategoryManagement from './pages/CategoryManagement';
import PageNotFound from './pages/PageNotFound';
import VendorManagement from './pages/VendorManagement';
import AssetManagement from './pages/AssetManagement';
import ViewAssetIndividual from './pages/ViewAssetIndividual';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    window.title = "Asset Management System";
  }, []);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<LoginForm />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='/users' element={<UserManagement />} />
        <Route path='/locations' element={<LocationManagement />} />
        <Route path='/forgot-password' element={<ResetPassword />} />
        <Route path='/category' element={<CategoryManagement />} />
        <Route path='/vendors' element={<VendorManagement />} />
        <Route path='/assets' element={<AssetManagement />} />
        <Route path='/asset' element={<ViewAssetIndividual />} />
        <Route path='/*' element={<PageNotFound />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
