import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, Slider, Row, Col, Select, DatePicker, Cascader, message, Card, Typography, Space, notification } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { backend_url } from '../../config/constants'
import axios from 'axios'
import dayjs from 'dayjs';
import { useForm } from 'antd/es/form/Form'
import { useLocation, useNavigate } from 'react-router-dom'
const { RangePicker } = DatePicker

const AssetFilter = ({ assetFilter, setAssetFilter, refresh, setRefresh }) => {
    const [open, setOpen] = useState(false)
    const [form] = useForm();
    const [api, contextHolder] = notification.useNotification();
    const [location, setLocation] = useState([])
    const [categories, setCategories] = useState([]);
    const [price, setPrice] = useState([0, 1000])
    const [user, setUser] = useState([]);
    const [vendor, setVendor] = useState([]);
    const url_handler = useLocation();
    const navigate = useNavigate();
    const auth = {
        headers: {
            'Content-type': 'application/json',
            'Authorization': `Bearer ${sessionStorage.getItem('token')}`
        }
    }
    const query = new URLSearchParams(url_handler.search);

    useEffect(() => {
        form.setFieldValue('asset_name_filter', 'contains');
        form.setFieldValue('asset_bill_number_filter', 'contains');
        form.setFieldValue('asset_voucher_code_filter', 'contains');
        form.setFieldValue('asset_code_filter', 'contains');
        axios.get(`${backend_url}/helpers.php?action=get_min_and_max_products`, auth).then((response) => {
            setPrice([response.data.min, response.data.max]);
        }).catch((error) => {
            api.error({
                message: 'Search API Failed',
                description: 'Unable to fetch minimum and maximum price range for assets'
            })
        });
        axios.get(`${backend_url}/users.php?group=user`, auth).then((response) => {
            let data = []
            response.data.users.forEach((user) => {
                data.push({
                    value: user.email,
                    username: user.username,
                    label: <div style={{ display: 'flex', justifyContent: 'left', flexDirection: 'column' }}>
                        <Typography.Text>{user.username}</Typography.Text>
                        <Typography.Text>{user.location}</Typography.Text>
                    </div>
                });
            });
            setUser(data);
        }).catch((error) => {
            message.error('Error fetching users');
        });
        axios.get(`${backend_url}/categories.php`, auth).then((response) => {
            let data = []
            response.data.forEach((category) => {
                let children = []
                category.children.forEach((child) => {
                    children.push({
                        value: child.id,
                        label: child.name
                    });
                });
                data.push({
                    value: category.name,
                    label: category.name,
                    children: children || []
                });
            });
            setCategories(data);
        }).catch((error) => {
            message.error('Error fetching categories');
        });

        axios.get(`${backend_url}/vendors.php`, auth).then((response) => {
            let state = [];
            let temp = []
            response.data.forEach((element) => {
                state.push({
                    label: <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'left' }}>
                        <Typography.Text>{element.name}</Typography.Text>
                        <Typography.Text style={{ fontSize: "12px", opacity: ".8" }}>{element.address}, {element.location.location}, {element.location.state}</Typography.Text>
                    </div>,
                    filter: [element.name, element.location.location, element.email],
                    value: element.id,
                })
                temp.push(element.state)

            })
            setVendor(state);
        }).catch((error) => {
            message.error('Error fetching vendors');
        });

        axios.get(`${backend_url}/location.php?status=active`, auth).then((response) => {
            let option = []
            response.data.forEach(location => {
                const found = option.find(option => option.value === location.state)
                if (!found) {
                    option.push({
                        value: location.state,
                        label: location.state,
                        children: [
                            {
                                value: location.uuid,
                                label: location.location,
                            }
                        ]
                    })
                } else {
                    option.forEach(option => {
                        if (option.value === location.state) {
                            option.children.push({
                                value: location.uuid,
                                label: location.location,
                            })
                        }
                    })
                }
                setLocation(option)
            })
        }).catch((error) => {
            message.error('Error fetching locations');
        });
    }, [assetFilter]);

    return (
        <>
            <Button type="primary" onClick={() => setOpen(!open)}>Filters</Button>
            <Drawer
                title="Advanced Filters"
                placement="right"
                closable={true}
                width={1000}
                onClose={() => setOpen(false)}
                open={open}
                extra={
                    <Space>
                        <Button onClick={() => {
                            form.resetFields();
                            setAssetFilter(null);
                            query.forEach((value, key) => {
                                query.delete(key);
                            })
                            navigate(`?${query.toString()}`);
                            setRefresh(!refresh);
                            setOpen(false)
                        }}>Reset</Button>
                        <Button onClick={() => {
                            setOpen(false)
                        }}>Close</Button>
                        <Button onClick={() => {
                            form.submit();
                            setOpen(false)
                            notification.success({
                                message: 'Filter Applied',
                                description: 'Filter has been applied successfully'
                            })
                        }} type="primary">
                            Search
                        </Button>
                    </Space>
                }
            >
                <Form
                    name="search_form"
                    initialValues={{ remember: true }}
                    layout="vertical"
                    form={form}
                    onFinish={(values) => {
                        let formData = {}
                        for (let key in values) {
                            if (values[key] && (values[key] !== null || values[key] !== undefined) && !(values[key] instanceof Array && values[key].length === 0)) {
                                switch (key) {
                                    case 'asset_category':
                                        formData[key] = [];
                                        console.log(values[key]);
                                        values[key].forEach((value) => {
                                            if (value.length === 1) {
                                                categories.forEach((category) => {
                                                    if (category.value === value[0]) {
                                                        category.children.forEach((sub_category) => {
                                                            formData[key].push(sub_category.value);
                                                        });
                                                    }
                                                });
                                            }
                                            else {
                                                console.log(["VALUE WITH LENGTH", value]);
                                                formData[key].push(value[1]);
                                            }
                                        });
                                        break;

                                    case 'asset_location':
                                        formData[key] = [];
                                        values[key].forEach((value) => {
                                            if (value.length === 1) {
                                                location.forEach((state) => {
                                                    if (state.value === value[0]) {
                                                        state.children.forEach((city) => {
                                                            formData[key].push(city.value);
                                                        });
                                                    }
                                                });
                                            } else {
                                                formData[key].push(value[1]);
                                            }
                                        });
                                        break;

                                    case 'asset_warranty_expiry':
                                    case 'asset_bought_on':
                                    case 'asset_disposal_date':
                                        formData[key] = [values[key][0].format('YYYY-MM-DD'), values[key][1].format('YYYY-MM-DD')];
                                        break;

                                    case 'asset_disposal_vendor':
                                        if (values[key] != null) {
                                            formData[key] = values[key];
                                        }
                                        break;

                                    default:
                                        formData[key] = values[key];
                                        break;
                                }
                            }
                        }
                        navigate(`?${query.toString()}`);
                        setAssetFilter(formData)
                        setRefresh(!refresh);
                    }}
                >
                    <Card
                        title="Quick Filter"
                    >
                        <Row gutter={16}>
                            <Col span={6}>
                                <Form.Item
                                    name="asset_disposal_mode"
                                    label="Show Items that are"
                                >
                                    <Select
                                        placeholder="Select Disposal Mode"
                                        allowClear
                                    >
                                        <Select.Option value="available">Available</Select.Option>
                                        <Select.Option value="disposed">Disposed</Select.Option>
                                        <Select.Option value="all">Both</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <Form.Item
                                    name="asset_status"
                                    label="Asset Status"
                                >
                                    <Select
                                        mode="multiple"
                                        placeholder="Select Asset Status"
                                        allowClear
                                    >
                                        <Select.Option value={0}>Pending Approval</Select.Option>
                                        <Select.Option value={1}>Approved</Select.Option>
                                        <Select.Option value={2}>Rejected</Select.Option>
                                        <Select.Option value={3}>Deleted</Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>
                    <br />
                    <Row gutter={18}>
                        <Col span={18}>
                            <Form.Item
                                name="asset_name"
                                label="Asset name"
                            >
                                <Input placeholder="Enter Asset Name" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="asset_name_filter"
                                label="Filter Mode"
                            >
                                <Select defaultValue={'match'}>
                                    <Select.Option value="starts">Starts with</Select.Option>
                                    <Select.Option value="ends">Ends with</Select.Option>
                                    <Select.Option value="contains">Contains</Select.Option>
                                    <Select.Option value="match">Exactly Match</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={18}>
                            <Form.Item
                                name="asset_bill_number"
                                label="Asset Bill Number"
                            >
                                <Input placeholder="Enter Bill Number" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="asset_bill_number_filter"
                                label="Filter Mode"
                            >
                                <Select defaultValue={'match'}>
                                    <Select.Option value="starts">Starts with</Select.Option>
                                    <Select.Option value="ends">Ends with</Select.Option>
                                    <Select.Option value="contains">Contains</Select.Option>
                                    <Select.Option value="match">Exactly Match</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={18}>
                            <Form.Item
                                name="asset_voucher_code"
                                label="Asset Voucher Code"
                            >
                                <Input placeholder="Enter Asset Voucher Code" />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="asset_voucher_code_filter"
                                label="Filter Mode"
                            >
                                <Select defaultValue={'match'}>
                                    <Select.Option value="starts">Starts with</Select.Option>
                                    <Select.Option value="ends">Ends with</Select.Option>
                                    <Select.Option value="contains">Contains</Select.Option>
                                    <Select.Option value="match">Exactly Match</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={18}>
                            <Form.Item
                                name="asset_code"
                                label="Asset Code"
                            >
                                <Input placeholder="Enter Asset Code" prefix={'OI/'} />
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                name="asset_code_filter"
                                label="Filter Mode"
                            >
                                <Select defaultValue={'match'}>
                                    <Select.Option value="starts">Starts with</Select.Option>
                                    <Select.Option value="ends">Ends with</Select.Option>
                                    <Select.Option value="contains">Contains</Select.Option>
                                    <Select.Option value="match">Exactly Match</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="asset_price"
                                label="Asset Price"
                            >
                                <Slider
                                    range
                                    defaultValue={[price[0], price[1]]}
                                    min={price[0]} max={price[1]}
                                    formatter={value => { return `Rs. ${value}` }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="asset_bought_on"
                                label="Acquisition Date"
                            >
                                <RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="asset_disposal_date"
                                label="Disposal Date"
                            >
                                <RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="asset_warranty_expiry"
                                label="Warranty Expiry Date"
                            >
                                <RangePicker style={{ width: "100%" }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item
                                name="asset_status"
                                label="Asset Status"
                            >
                                <Select
                                    mode="multiple"
                                    placeholder="Select Asset Status"
                                    allowClear
                                >
                                    <Select.Option value={0}>Pending Approval</Select.Option>
                                    <Select.Option value={1}>Approved</Select.Option>
                                    <Select.Option value={2}>Rejected</Select.Option>
                                    <Select.Option value={3}>Deleted</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="asset_location"
                                label="Asset Location"
                            >
                                <Cascader
                                    multiple={true}
                                    placeholder="Select a location"
                                    options={location}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>

                        <Col span={12}>
                            <Form.Item
                                name="asset_category"
                                label="Select Category"
                            >
                                <Cascader
                                    multiple={true}
                                    placeholder="Select a category"
                                    options={categories}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="asset_user_email"
                                label="Asset User"
                            >
                                <Select
                                    placeholder="Select a user"
                                    options={user}
                                    showSearch
                                    mode="multiple"
                                    allowClear
                                    filterOption={(input, option) => {
                                        return option.username.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={12}>
                            <Form.Item
                                name="asset_vendor"
                                label="Vendor"
                            >
                                <Select
                                    placeholder="Select a vendor"
                                    options={vendor}
                                    showSearch
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                name="asset_disposal_vendor"
                                label="Disposal Vendor"
                            >
                                <Select
                                    placeholder="Select a vendor"
                                    options={vendor}
                                    showSearch
                                    mode="multiple"
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Drawer>
        </>
    )
}

export default AssetFilter