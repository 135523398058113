import React, { useEffect } from 'react';
import { Avatar, Button, Layout, Space, Spin, Tag, Typography, message, notification, Descriptions, Card } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useState } from 'react';
import axios from 'axios';
import { backend_url } from '../config/constants';
import NavBar from '../components/Navbar';
import { Link, useLocation } from 'react-router-dom';
const { Header, Sider, Content } = Layout;

const ViewAssetIndividual = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const userName = userData.username;
    const location = useLocation();
    const id = location.search
    const assetId = id.split('=')[1]

    const [collapsed, setCollapsed] = useState(false);
    const [loader, setloader] = useState(
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spin tip="Loading" size="large"></Spin>
        </div>)

    useEffect(() => {
        document.title = "View Asset Details";
        const auth = {
            headers: {
                'Content-type': 'application/json',
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`
            }
        }
        axios.get(`${backend_url}/products.php?id=${assetId}`, auth).then((response) => {
            const assetData = response.data
            setloader(
                <Card title={`Asset Details for OI/${assetData[0].asset_code}`} bordered={false} style={{ margin: '20px' }}>
                    <Descriptions bordered>
                        <Descriptions.Item label="Asset Code"><b>OI/</b>{assetData[0].asset_code}</Descriptions.Item>
                        <Descriptions.Item label="Asset Name">{assetData[0].asset_name}</Descriptions.Item>
                        <Descriptions.Item label="Bill Number">{assetData[0].asset_bill_number}</Descriptions.Item>
                        <Descriptions.Item label="Voucher Code">{assetData[0].asset_voucher_code}</Descriptions.Item>
                        <Descriptions.Item label="Bought On">{assetData[0].asset_bought_on}</Descriptions.Item>
                        <Descriptions.Item label="Price">₹{assetData[0].asset_price}</Descriptions.Item>
                        <Descriptions.Item label="Depreciation">{assetData[0].asset_depreciation}% Year on Year</Descriptions.Item>
                        <Descriptions.Item label="Depreciated Value">₹{assetData[0].asset_depreciated_value}</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Location" bordered style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="Name">{assetData[0].asset_location_name}</Descriptions.Item>
                        <Descriptions.Item label="State">{assetData[0].asset_location_state}</Descriptions.Item>
                        <Descriptions.Item label="Status">{(assetData[0].asset_location_status === 0) ?
                            <Tag color="red">
                                Inactive
                            </Tag> :
                            <Tag color="green">
                                Active
                            </Tag>
                        }</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="User" bordered style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="Email">{assetData[0].asset_user_email}</Descriptions.Item>
                        <Descriptions.Item label="Name">{assetData[0].asset_user_name}</Descriptions.Item>
                        <Descriptions.Item label="Phone Number">{assetData[0].asset_user_phone_number}</Descriptions.Item>
                        <Descriptions.Item label="Access Level">{
                            (assetData[0].asset_user_access_level === 0) ?
                                <Tag color="purple">
                                    Project Head
                                </Tag> :
                                (assetData[0].asset_user_access_level === 1) ?
                                    <Tag color="green">
                                        Manager
                                    </Tag>
                                    :
                                    <Tag color="blue">
                                        Administrator
                                    </Tag>
                        }</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Vendor" bordered style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="Name">{assetData[0].asset_vendor_name}</Descriptions.Item>
                        <Descriptions.Item label="Email">{assetData[0].vendor_email}</Descriptions.Item>
                        <Descriptions.Item label="Phone">{assetData[0].vendor_phone}</Descriptions.Item>
                        <Descriptions.Item label="Address">{assetData[0].vendor_address}</Descriptions.Item>
                        <Descriptions.Item label="Vendor Status">{(assetData[0].asset_vendor_status === 0) ?
                            <Tag color="red">
                                Inactive
                            </Tag> :
                            <Tag color="green">
                                Active
                            </Tag>
                        }</Descriptions.Item>
                        <Descriptions.Item label="Location Name">{assetData[0].vendor_location_name}</Descriptions.Item>
                        <Descriptions.Item label="Location State">{assetData[0].vendor_location_state}</Descriptions.Item>
                        <Descriptions.Item label="Location Status">{(assetData[0].vendor_location_status === 0) ?
                            <Tag color="red">
                                Inactive
                            </Tag> :
                            <Tag color="green">
                                Active
                            </Tag>
                        }</Descriptions.Item>
                    </Descriptions>
                    {
                        (assetData[0].asset_disposal_date !== null) && <Descriptions title="Disposal Vendor" bordered style={{ marginTop: '20px' }}>
                            <Descriptions.Item label="Disposed On">{new Date(assetData[0].asset_disposal_date).toLocaleDateString()}</Descriptions.Item>
                            <Descriptions.Item label="Name">{assetData[0].asset_disposal_vendor_name}</Descriptions.Item>
                            <Descriptions.Item label="Email">{assetData[0].asset_disposal_vendor_email}</Descriptions.Item>
                            <Descriptions.Item label="Phone">{assetData[0].asset_disposal_vendor_phone}</Descriptions.Item>
                            <Descriptions.Item label="Address">{assetData[0].asset_disposal_vendor_address}</Descriptions.Item>
                            <Descriptions.Item label="Vendor Status">{(assetData[0].asset_disposal_vendor_status === 0) ?
                                <Tag color="red">
                                    Inactive
                                </Tag> :
                                <Tag color="green">
                                    Active
                                </Tag>
                            }</Descriptions.Item>
                            <Descriptions.Item label="Location Name">{assetData[0].vendor_disposal_location}</Descriptions.Item>
                            <Descriptions.Item label="Location State">{assetData[0].vendor_disposal_state}</Descriptions.Item>
                            <Descriptions.Item label="Location Status">{(assetData[0].vendor_disposal_status === 0) ?
                                <Tag color="red">
                                    Inactive
                                </Tag> :
                                <Tag color="green">
                                    Active
                                </Tag>
                            }</Descriptions.Item>
                        </Descriptions>
                    }
                    <Descriptions title="Category" bordered style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="Project Head">{assetData[0].asset_parent_category_name}</Descriptions.Item>
                        <Descriptions.Item label="Accounts Ledger">{assetData[0].asset_category_name}</Descriptions.Item>
                        <Descriptions.Item label="Status">{(assetData[0].asset_category_status === 0) ?
                            <Tag color="red">
                                Inactive
                            </Tag> :
                            <Tag color="green">
                                Active
                            </Tag>
                        }</Descriptions.Item>
                    </Descriptions>
                    <Descriptions title="Additional Information" bordered style={{ marginTop: '20px' }}>
                        <Descriptions.Item label="Description" span={3}>{assetData[0].asset_description}</Descriptions.Item>
                        <Descriptions.Item label="Remark" span={3}>{assetData[0].asset_remark}</Descriptions.Item>
                        <Descriptions.Item label="Warranty Expiry">{assetData[0].asset_warranty_expiry}</Descriptions.Item>
                        <Descriptions.Item label="Bill Location">
                            <Link onClick={() => {
                                notification.info({
                                    message: 'Bill Location',
                                    description: 'Redirecting to destination page',
                                })
                                const location = `${backend_url}/uploads/${assetData[0].asset_bill_location}`
                                window.open(location, '_blank')
                            }}>
                                {assetData[0].asset_bill_location}
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="Warranty Location">
                            <Link onClick={() => {
                                notification.info({
                                    message: 'Warranty Location',
                                    description: 'Redirecting to destination page',
                                })
                                const location = `${backend_url}/uploads/${assetData[0].asset_warranty_location}`
                                window.open(location, '_blank')
                            }}>
                                {assetData[0].asset_warranty_location}
                            </Link>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">{
                            getAssetStatus(assetData[0].asset_status)
                        }</Descriptions.Item>
                    </Descriptions>
                </Card>
            )
        }).catch((error) => {
            console.log(error)
        })
    }, [])

    const getAssetStatus = (status) => {
        switch (status) {
            case 0:
                return <Tag color="red">Pending</Tag>
            case 1:
                return <Tag color="green">Approved</Tag>
            case 2:
                return <Tag color="blue">Rejected</Tag>
            case 3:
                return <Tag color="purple">Deleted</Tag>
            default:
                break;
        }
    }

    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        View Asset Details
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', overflowY: 'auto' }}>

                    <div style={{ padding: 10, background: '#fff', }}>
                        {loader}
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default ViewAssetIndividual
