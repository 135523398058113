import { Avatar, Button, Layout, Space, Carousel, Typography, message, notification, Statistic, Row, Col, Table, Drawer } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import NavBar from '../components/Navbar';
import axios from 'axios';
import { backend_url } from '../config/constants';
import { Link } from 'react-router-dom';
import AssetsByCategory from '../components/graphs/TotalAssetsByCountCategory';
import AssetsByPrice from '../components/graphs/TotalAssetsByPriceCategory';
import AssetsByUserCount from '../components/graphs/TotalAssetsByCountUser';
import AssetsByPriceUser from '../components/graphs/TotalAssetsByPriceUsers';
import QuarterlySpendingChart from '../components/graphs/QuarterlySpendingByLocation';
import QuarterlySpendingByCategoryChart from '../components/graphs/QuarerlySpendingByCategoryChart';
const { Header, Sider, Content } = Layout;

const Dashboard = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    const userName = userData.username;
    const [openExtraText, setOpenExtraText] = useState(false)
    const [analytics, setAnalytics] = useState([])
    const [graphs, setGraphs] = useState({
        category_by_count: [],
        category_by_value: [],
        user_by_value: [],
        user_by_count: [],
        quarterly_spending: [],
        quarterly_spending_by_category: []
    })

    const auth = {
        headers: {
            'Content-type': 'application/json',
            Authorization: `Bearer ${sessionStorage.getItem('token')}`
        },
    }

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        document.title = "Dashboard";
        axios.get(`${backend_url}/stats.php?stat=asset`, auth).then((response) => {
            setAnalytics(response.data)
        }).catch((error) => {
            message.error('Could not fetch data')
        })
        axios.get(`${backend_url}/stats.php?stat=dashboard`, auth).then((response) => {
            setGraphs(response.data)
        }).catch((error) => {
            message.error('Could not fetch data')
        })
    }, [])

    const extractWarrantyData = (data) => {
        let arr = []
        for (let i = 0; i < data.length; i++) {
            arr.push(
                {
                    title: data[i].name,
                    code: data[i].code,
                    expiry: new Date(data[i].expiry).toLocaleDateString()
                }
            )
        }
        return <Table
            columns={[
                {
                    title: 'Name',
                    dataIndex: 'title',
                    key: 'title',
                },
                {
                    title: 'Code',
                    dataIndex: 'code',
                    key: 'code',
                    render: (text) => <Link to={`/asset?id=${text}`}>{text}</Link>
                },
                {
                    title: 'Expiry',
                    dataIndex: 'expiry',
                    key: 'expiry',
                }
            ]}
            dataSource={arr}
        />
    }

    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        Dashboard
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', overflowY: 'auto' }}>

                    <div style={{ padding: "20px", background: "white", borderRadius: ".5rem" }}>
                        <Row gutter={16}>
                            <Col span={6}>
                                <Statistic title="Currently Managed Assets" value={analytics["active_assets"]} />
                            </Col>
                            <Col span={6}>
                                <Statistic title="Holding Assets Worth" value={analytics["depreciated_assets_value"]} suffix="Rs" precision={2} />
                            </Col>
                            <Col span={6}>
                                <Statistic title="Assets Pending Approval" value={analytics["pending_approval"]} />
                            </Col>
                            <Col span={6}>
                                <a onClick={() => setOpenExtraText(extractWarrantyData(analytics["warranty_data"]))}><Statistic title="Warranty Expiry in 30 days" value={analytics["upcoming_warranty_expiry"]} /></a>
                            </Col>
                        </Row>
                        <br />
                        <QuarterlySpendingChart quarterlySpendingData={graphs["quarterly_spending"]} />
                        <QuarterlySpendingByCategoryChart quarterlySpendingByCategoryData={graphs["quarterly_spending_by_category"]} />
                        <Row gutter={16} style={{ marginTop: "20px" }}>
                            <Col span={12}>
                                <AssetsByCategory category_by_count={graphs["category_by_count"]} />
                            </Col>
                            <Col span={12}>
                                <AssetsByPrice category_by_value={graphs["category_by_value"]} />
                            </Col>
                        </Row>
                        <Row gutter={16} style={{ marginTop: "20px" }}>
                            <Col span={12}>
                                <AssetsByUserCount user_by_count={graphs["user_by_count"]} />
                            </Col>
                            <Col span={12}>
                                <AssetsByPriceUser user_by_value={graphs["user_by_value"]} />
                            </Col>
                        </Row>
                        <br />

                    </div>
                </Content>
            </Layout>
            <Drawer
                title="View Full Information"
                placement="bottom"
                closable={true}
                height={500}
                onClose={() => setOpenExtraText(false)}
                open={(openExtraText !== false)}
            >
                <Typography.Text>
                    {openExtraText ? openExtraText : "No data available"}
                </Typography.Text>
            </Drawer>
        </Layout>
    );
};
export default Dashboard