import { Avatar, Button, Layout, Tag, Space, Typography } from 'antd';
import { UserOutlined, MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import NavBar from '../components/Navbar';
import AdminAccountSettings from '../components/AdminAccountSettings';
import UserAccountSettings from '../components/UserAccountSettings';
const { Header, Sider, Content } = Layout;

const Settings = () => {
    const userData = JSON.parse(localStorage.getItem('userdata'));
    console.log(userData)
    const userName = userData.username;   

    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        document.title = "Settings";
    }, []);

    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh' }}>
            <Sider width={200} theme="dark" style={{ paddingTop: '25px', paddingLeft: '10px', paddingRight: '10px' }} trigger={null} collapsible collapsed={collapsed}>
                <NavBar collapsed={collapsed} />
            </Sider>
            <Layout>
                <Header style={{ background: '#fff', padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Button
                        type="text"
                        icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            fontSize: '16px',
                            width: 64,
                            height: 64,
                        }}
                    />
                    <Typography.Title level={3} style={{ margin: 0 }}>
                        Settings
                    </Typography.Title>
                    <Space style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '16px', alignItems: 'center' }}>
                        <Avatar icon={<UserOutlined />} />
                        <span style={{ marginRight: '16px' }}>Welcome, {userName}</span>
                    </Space>
                </Header>
                <Content style={{ margin: '24px 16px 0 16px', overflowY: 'auto', backgroundColor: "white", borderRadius: ".75rem" }}>
                    {
                        (userData.access_level === 1 || userData.access_level === 2) ? AdminAccountSettings() : UserAccountSettings()
                    } 
                </Content>
            </Layout>
        </Layout>
    );
};


export default Settings