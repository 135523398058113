import React, { useState, useEffect } from 'react';
import Chart from 'react-apexcharts';

const QuarterlySpendingByCategoryChart = ({ quarterlySpendingByCategoryData }) => {
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'line',
        height: 350,
        zoom: {
          enabled: false
        }
      },
      xaxis: {
        categories: [],
        title: {
          text: 'Quarter'
        }
      },
      yaxis: {
        title: {
          text: 'Total Spending'
        }
      },
      title: {
        text: 'Quarterly Spending by Category',
        align: 'left'
      },
      legend: {
        position: 'top'
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter: function (value) {
            return "Rs " + value.toFixed(2); // Format the tooltip value as needed
          }
        }
      }
    }
  });

  useEffect(() => {
    if (quarterlySpendingByCategoryData && quarterlySpendingByCategoryData.length > 0) {
      const categories = generateQuarterlyCategories();

      const seriesData = {};
      quarterlySpendingByCategoryData.forEach(item => {
        const quarter = item.quarter;
        const category = item.category;
        const total = item.total;

        if (!seriesData[category]) {
          seriesData[category] = Array(categories.length).fill(0);
        }
        const index = categories.indexOf(quarter);
        seriesData[category][index] = total;
      });

      const series = Object.keys(seriesData).map(category => ({
        name: category,
        data: seriesData[category]
      }));

      setChartData(prevState => ({
        ...prevState,
        series: series,
        options: {
          ...prevState.options,
          xaxis: {
            ...prevState.options.xaxis,
            categories: categories
          }
        }
      }));
    }
  }, [quarterlySpendingByCategoryData]);

  const generateQuarterlyCategories = () => {
    const currentYear = new Date().getFullYear();
    const quarters = [];
    for (let year = currentYear - 3; year <= currentYear; year++) {
      for (let quarter = 1; quarter <= 4; quarter++) {
        quarters.push(`Q${quarter} ${year}`);
      }
    }
    return quarters;
  };

  return (
    <div>
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default QuarterlySpendingByCategoryChart;
