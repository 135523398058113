import React, { useState } from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { message, Upload } from 'antd';
const { Dragger } = Upload;

const FileUploadWidget = ({ file, setFile, form, name }) => {
    const props = {
        onRemove: (file) => {
            setFile(null);
            form.delete(name);
        },
        beforeUpload: (file) => {
            setFile(file);
            if (form && name) {
                form.append(name, file);
            }
            return false;
        },
        file,
        maxCount: 1,
        accept: ".pdf, .doc, .docx, .png, .jpg, .jpeg",
    };
    return (
        <>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Limited to a maximum of 1 file. Less than 5MB.
                </p>
            </Dragger>
        </>
    );
};
export default FileUploadWidget;